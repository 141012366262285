import plant from "../assets/Image-6.webp";
import fca from "../assets/Screenshot 2021-10-06 at 12.21.47.webp";
import biba from "../assets/british-insurance-brokers-association-biba-logo-37B1CD0DD6-seeklogo.com.webp";

// import alloy from "../assets/alloy2x.webp";

// import tyre from "../assets/tyre2x.webp";
// import gap from "../assets/gap2x.webp";
import relaxing from "../assets/undraw_Relaxing_at_home_re_mror.svg";
import rating from "../assets/d4kmbbud@2x.webp";

import gapHeader from "../assets/gap.webp";
import tyreHeader from "../assets/tyre.webp";
import cosmetic from "../assets/cosmetic.webp";
import alloyHeader from "../assets/alloy.webp";

import car_ins_homepage from "../assets/car_ins_homepage.webp";
import car_ins_par_homepage from "../assets/car_ins_par_homepage.webp";

import tyreInsurance2 from "../assets/tyre_insurance_2.webp";
import cosmeticInsurance3 from "../assets/cosmetic_insurance_3.webp";
import excessInsurance3 from "../assets/excess_insurance_3.webp";
import gapInsurance3 from "../assets/GAP_insurance_3.webp";
import vehicleWarranty3 from "../assets/vehicle_warranty_3.webp";
import vehicleWarranty4 from "../assets/vehicle_warranty_4.webp";
import weddingInsurance3 from "../assets/wedding_insurance_3.webp";

//landing_pdf

import more_info_invicuts from "../assets/more_info_invictus.pdf";
import more_info_smh from "../assets/more_info_smh.pdf";
import more_info_aib from "../assets/more_info_aib.pdf";
import more_info_wt from "../assets/more_info_wt.pdf";


//checkout cards first face image
import alloyInsurance from "../assets/alloy_insurance.webp";
import gapInsurance2 from "../assets/gap_insurance.webp";
import tyreInsurance from "../assets/tyre_insurance.webp";
import cosmeticInsurance from "../assets/cosmetic_repair.webp";
import mbi from "../assets/mbi.webp";

import landing_smh_logo from "../assets/landing-smh-logo.png";
import invictuspng from "../assets/invictus.png";
import DA from "../assets/DA.png";
import aib from "../assets/aib_logo.png"

// SMH headers

import imgAlloy from "../assets/imgAlloy.webp";
import imgAlloypng from "../assets/imgAlloypng.png";
import imgTyre from "../assets/imgTyre.webp";
import imgTyrepng from "../assets/imgTyrepng.png";
import imgCosmetic from "../assets/imgCosmetic.webp";
import imgCosmeticpng from "../assets/imgCosmeticpng.png";
import imgWarranty from "../assets/imgWarranty.webp";
import imgWarrantypng from "../assets/imgWarrantypng.png";
import imgGAP from "../assets/imgGAP.webp";
import imgGAPpng from "../assets/imgGAPpng.png";
import imgExcess from "../assets/imgExcess.webp";
import imgExcesspng from "../assets/imgExcesspng.png";
import imgWedding from "../assets/imgWedding.webp";
import imgWeddingpng from "../assets/imgWeddingpng.png";
import imgAboutUs from "../assets/imgAboutUs.webp";
import imgAboutUspng from "../assets/imgAboutUspng.png";
import imgTree from "../assets/imgTree.webp";
import imgTreepng from "../assets/imgTreepng.png";
import imgHome from "../assets/imgHome.webp";
import imgHomepng from "../assets/imgHomepng.png";


// Invictus header

// import imgAlloy from "../assets/inv/imgAlloy.webp";
// import imgAlloypng from "../assets/inv/imgAlloypng.png";
// import imgTyre from "../assets/inv/imgTyre.webp";
// import imgTyrepng from "../assets/inv/imgTyrepng.png";
// import imgCosmetic from "../assets/inv/imgCosmetic.webp";
// import imgCosmeticpng from "../assets/inv/imgCosmeticpng.png";
// import imgWarranty from "../assets/inv/imgWarranty.webp";
// import imgWarrantypng from "../assets/inv/imgWarrantypng.png";
// import imgGAP from "../assets/inv/imgGAP.webp";
// import imgGAPpng from "../assets/inv/imgGAPpng.png";
// import imgExcess from "../assets/inv/imgExcess.webp";
// import imgExcesspng from "../assets/inv/imgExcesspng.png";
// import imgWedding from "../assets/inv/imgWedding.webp";
// import imgWeddingpng from "../assets/inv/imgWeddingpng.png";
// import imgAboutUs from "../assets/inv/imgAboutUs.webp";
// import imgAboutUspng from "../assets/inv/imgAboutUspng.png";
// import imgTree from "../assets/inv/imgTree.webp";
// import imgTreepng from "../assets/inv/imgTreepng.png";
// import imgHome from "../assets/inv/imgHome.webp";
// import imgHomepng from "../assets/inv/imgHomepng.png";


import weddingBackgroundPng from "../assets/wedding_bk_card.jpeg";
import weddingBackgroundWebp from "../assets/wedding_background.webp";


import giving from "../assets/about-us-giving-back.webp";
import aboutUsExperience from "../assets/about-us-experience.webp";
import aboutUs2 from "../assets/about-us-2.webp"

import newImg from "../assets/car_insurance_products.jpg";
import newImg2 from "../assets/vehicle_warranty_4.jpeg";
import newImgwebp from "../assets/car_insurance_products.webp";
import newImg2Webp from "../assets/vehicle_warranty_4.webp";



/// resized image
import alloy26 from "../assets/alloy26.webp";
import gap26 from "../assets/gap26.webp";
import tyre26 from "../assets/tyre26.webp";
import cosmetic26 from "../assets/scratch26.webp";
import mbi26 from "../assets/excess26.webp";

//tree plated page assets
import forestHeader from "../assets/Forest---Header---1.jpg";
import importanceOfTrees from "../assets/pledge_trees.webp";
import air from "../assets/pledge_air.webp";
import water from "../assets/pledge_water.webp";
import biodiversity from "../assets/pledge_biodiversity.webp";
import socialImpact from "../assets/pledge_socialimpact.webp";
import health from "../assets/pledge_health.webp";
import climate from "../assets/pledge_climate.webp";



import alloy_paragraph_image from "../assets/alloy_paragraph_image.webp";
import excess_insurance_paragraph from "../assets/excess_insurance_paragraph.jpeg";
import gap_insurance_paragraph from "../assets/gap_insurance_paragraph.webp";
import warranty_insurance_paragraph from "../assets/warranty_insurance_paragraph.webp";
import cosmetic_paragraph_image from "../assets/cosmetic_paragraph_image.webp";
import wedding_insurance_paragraph from "../assets/wedding_insurance_paragraph.webp"
import treeLeft from "../assets/treeLeft.png"

import logo_smh from "../assets/logo.webp"
import logo_smh_white from "../assets/smh-white.webp"
import logo_footer_white from "../assets/smh_logo_white.png"

import logo_invictus from "../assets/logo-invictus.webp"
import logo_invictus_white from "../assets/logo-invictus-white.webp"


export const MyTheme = {
  pageTitle: "Landing page",
  pageUrl: "savemoneyhub.com",
  email: "info@loyaltycoversoftware.co.uk",
  phone: "01615051420",
  displayPhone: "0161 505 1420",
  externalLogo: "https://portal.savemoneyhub.com/Content/themes/savemoneyhub/logo.png",
  twitterUrl: "https://twitter.com/savemoneyhubuk",
  cookie: "agg_savemoneyhubCookie",
  logo: logo_smh,
  logo_white: logo_smh_white,
  logo_footer: logo_footer_white,
  color: "#ED74AA",
  imgwebp: imgHome,
  imgpng: imgHomepng,
}

// export const MyTheme = {
//   pageTitle: "Invictus Protect Limited",
//   pageUrl: "invictusprotect.com/",
//   email: "info@invictusprotect.com",
//   phone: "01615051404",
//   displayPhone: "0161 505 1404",
//   externalLogo:"",
//   twitterUrl: "",
//   cookie: "invictusprotectCookie",
//   logo: logo_invictus,
//   logo_white: logo_invictus_white,
//   logo_footer: logo_invictus_white,
//   color: "#253551",
//   imgwebp: imgHome,
//   imgpng: imgHomepng,
// }


export const landingProviders = [
  {
    provider_id: '99202210',
    logo: 'smh',
    redirectURL: "https://checkout.loyaltycoversoftware.com/",
    moreInfo: more_info_smh
  },
  {
    provider_id: '99202211',
    logo: 'invictus',
    redirectURL: "https://checkout.loyaltycoversoftware.com/",
    moreInfo: more_info_invicuts

  },
  {
    provider_id: '99202212',
    logo: 'driveassured',
    redirectURL: "https://checkout.loyaltycoversoftware.com/",
    moreInfo: more_info_aib

  },
  {
    provider_id: '99202213',
    logo: 'warrantytoday',
    redirectURL: "https://checkout.loyaltycoversoftware.com/",
    moreInfo: more_info_wt

  }
];

export const policyLanding = {

  smh: {
    'logo': landing_smh_logo,
    'What do we cover': [
      {
        text: `The  SaveMoneyHub  insured  vehicle  warranty  (MBI)  covers  the  sudden  Mechanical  and 
        Electrical  failure  or  the  vehicle  parts,  including  but  not  limited  to:  Engine,  Gearbox,  Clutch 
        (excluding where the condition is due to wear & tear or the clutch is burnt out), Drive Systems, 
        Four Wheel Drive System, Wheel Bearings, Fuel System, Cooling System, Air Conditioning, 
        Steering System, Suspension System, Braking System (Excluding brake pads, discs, shoes & 
        callipers), Electrical System, Entertainment & Communication Systems. `
      },
      {
        text: "Cover available for Car or Light Commercial Vehicle (LCV)."
      },
      {
        text: `Diamond (All Mechanical & Electrical cover – only parts listed are excluded from cover) for
        vehicles up to 10 years old and 100,000 miles at policy inception`
      },
      {
        text: `Ruby (Listed Parts only are covered) for vehicles up to 12 years old and/or 120,000 miles at
        policy inception.`
      },
      {
        text: "Cover available for Car, Motorbike or Motorhome."
      },
      {
        text: `Cover available (at an additional cost) for Hire & Reward – Driving Schools / Courier / Chauffeur
        and Taxi.`
      },
      {
        text: `Day one cover of electrical and mechanical (except those stated in section 6 of our policy
          document). Failure of insured parts due to Wear & Tear starts 60 days after the policy start date.`
      },
      {
        text: "Unlimited number of claims up to the purchase price / value of your vehicle."
      },
      {
        text: `Service History not required to purchase cover (Routine servicing is required to be carried out
          during the policy term).`
      },
      {
        text: `Fully insured product, backed by an insurer that meets "Solvency II" requirements – Acasta
        European Insurance Company Limited.`
      },
      {
        text: "No Pre-Inspection or Health Check Required."
      },
      {
        text: "No claims excess."
      },
      {
        text: "Use any VAT registered garage or our approved repairer."
      },
      {
        text: "Covers mechanical and electrical failure whilst driving in Europe."
      },
      {
        text: "Contribution towards diagnostics or reprogramming on an agreed claim."
      },
      {
        text: "£50 per day contribution towards a courtesy car on a valid claim for up to seven days."
      },
      {
        text: "Up to £50 towards the cost of towing the vehicle to the nearest repairer on a valid claim."
      },
      {
        text: `Up to £60 including VAT towards overnight accommodation or a return rail fare if you are unable
        to return home on a valid claim.`
      },
      {
        text: "Choice of labour rates and claim limits."
      }
    ],
    'Optional Upgrades': [
      {
        title: "Wear & Tear ",
        text: `Our optional upgrade to include Boost offers cover for “Wear & Tear” which is
        the expected deterioration or performance of a part due to the cars age or mileage and if a part
        breaks, or fails, due to the mileage or running of the car, it is typically considered as failure due
        to “Wear & Tear". Where a claim is for a covered component, and the claim would be valid
        except for Wear and Tear.`
      },
      {
        title: "Boost",
        text: "Our optional upgrade to include Boost offers cover for:"
      },
      {
        subtitle: "Satellite Navigation System",
        text: "Includes the satellite navigation system in the Vehicle, where part of the manufacturer’s original specification, including the LED/digital display."
      },
      {
        subtitle: "Battery",
        text: `Includes the Vehicle battery (except the drive power battery of hybrid or electrically
          driven vehicles).`
      },
      {
        subtitle: "Diesel Particulate Filter",
        text: "Includes blocked, contaminated, or damaged filters."
      },
      {
        subtitle: "In-car entertainment systems / Multimedia/Sound Equipment",
        text: "Includes multimedia/sound equipment, where part of the manufacturer’s original specification, including TV units, LED/Digital display, and remote signal units"
      },
      {
        subtitle: "Catalytic Converter",
        text: "Includes the catalytic converter, where blocked, contaminated, or damaged"
      }
    ],
    'What is not covered': [

      {
        text: 'Vehicles used for competition purposes, rallying, time trials or racing'
      },
      {
        text: 'Air bags and systems (inc. switches, modules, and control unit)          '
      },
      {
        text: 'Serviceable Parts, servicing, adjusting, or cleaning parts'
      },
      {
        text: 'Wiring harness, wiring terminals, Linkages, cables, lock barrels, keys, key fobs, drop links, window regulators & cables, auxiliary drive belts, fuel tank'
      },
      {
        text: 'All bodywork handles and hinges, brackets, mountings, supports, brightwork, paint, glass (including front & rear heated screens & elements), weatherstrips, rubber seals, sheet metal, wiper arms, wheels, fixings and fastening devices, Interior/exterior trim, sunroof panels, carpets, seats and seat belts.'
      },
      {
        text: 'Any claim which is reported to the Administrator more than 14 days after the relevant fault is discovered'
      },
      {
        text: 'Any claim where the repair has not commenced within 14 days of the fault being reported and authorised by the Administrator'
      },
      {
        text: 'Any claim when the vehicle has not been serviced in line with the manufacturer’s guidelines'
      },
      {
        text: `Any damage caused by: Neglect, abuse, corrosion, freezing, water ingress or any foreign
        body or fluid, getting into or onto a part; lack of servicing, the effects of over-heating, whether
        caused by an insured part or not, parts which have been fitted incorrectly; or consequential
        damage, faults or defects which are likely to have existed before the start of this warranty`
      },
      {
        text: 'Any pre-existing fault, apparent on the vehicle at the policy start date.'
      },
    ],
    'Key Exclusions - with Boost and Wear & Tear': [
      {
        text: "Vehicle Turbo"
      }
    ],
    'Key Exclusions - without Boost and Wear & Tear': [
      {
        text: `Catalytic convertor, Diesel Particulate Filter, Satellite Navigation System, In-car
        entertainment, and Wear & Tear`
      }
    ],
    'Claim Limits': [
      {
        text: 'Claim Limit - A choice of claim limits from £2,500 to £5,000 per individual claim, up to the purchase price / value of the vehicle at the policy start date.'
      }
    ],
    'Labour Rates': [
      {
        text: "Labour Rates - A choice of Labour rates from £70.00 per hour to £100 per hour"
      }
    ],
    'Want to know more?': [
      {
        text: "In addition to the details above, more information can be found on the providers website"
      }
    ],
    'The legal bit': [
      {
        text: `SaveMoneyHub.com is a trading style of Rest Ashored Ltd who are Authorised and Regulated
        by the Financial Conduct Authority. Our Financial Services Register Number is 732530. You
        may check this on the FCA Register by visiting the FCA’s website, www.fca.org.uk/register or
        by contacting the FCA on 0800 111 6768.
        `,
      },
      {
        text: 'Rest Ashored Ltd is registered in England No. 08179370'
      }
    ],
    'About the provider': [
      {
        text: 'SaveMoneyHub - Competitive, quality vehicle protection products – all under one roof!'
      },
      {
        text: `SaveMoneyHub, which currently provides cover to over £200 million worth of UK vehicles,
        was started for one core reason – I bet you’ll never guess what that was? That’s right! To
        save our customers both time and money. But not only that. The team here at
        SaveMoneyHub believe in keeping things simple, which is why we cater for all of your
        vehicle’s needs, all in-house, all under one roof. That way, you don’t have to keep shopping
        around each time any slight thing happens with your vehicle.
        All our product range is underwritten by Insurers that are “A” rated or meet “Solvency II”
        requirements and are covered protected by the FSCS.`
      },
    ],
    'Why use us?': [
      {
        text: 'Peace of mind'
      },
      {
        text: 'Quality protection, at a great price'
      },
      {
        text: 'FCA Regulated & FSCS protected'
      },
      {
        text: 'All products are fully insured and backed by our panel of Insurers who are all "A" Rated and/or meet "Solvency II" requirements'
      },
      {
        text: 'Cover from Day One, on selected products'
      },
      {
        text: 'An expert team of UK based insurance specialists and claims handlers'
      },
      {
        text: 'Over 50 years of experience'
      },
      {
        text: 'On hand whenever you need us'
      },
      {
        text: 'Cover available for 1, 2 or 3 years on most products'
      },
      {
        text: 'Providing product protection to over £200 million worth of vehicles across the UK'
      },
      {
        text: 'You even get to help nurture the environment - A tree planted with every purchase'
      }
    ]
  },
  driveassured: {
    'logo': aib,
    'What do we cover': [
      {
        text: `The Alternative Insurance Brokers insured vehicle warranty (MBI) covers the sudden
        Mechanical and Electrical failure or the vehicle parts, including but not limited to: Engine,
        Gearbox, Clutch (excluding where the condition is due to wear & tear or the clutch is burnt out),
        Drive Systems, Four Wheel Drive System, Wheel Bearings, Fuel System, Cooling System, Air
        Conditioning, Steering System, Suspension System, Braking System (Excluding brake pads,
        discs, shoes & callipers), Electrical System, Entertainment & Communication Systems.`
      },
      {
        text: "Cover available for Car or Light Commercial Vehicle (LCV)"
      },
      {
        text: `Gold (All Mechanical & Electrical cover – only parts listed are excluded from cover) for vehicles
        up to 10 years old and 100,000 miles at policy inception, Cover includes Wear & Tear and
        Boost protection`
      },
      {
        text: `Silver (All Mechanical & Electrical cover – only parts listed are excluded from cover) for vehicles
        up to 10 years old and 100,000 miles at policy inception, Cover excludes Wear & Tear and
        Boost protection`
      },
      {
        text: "Cover available for Motorbike or Motorhome"
      },
      {
        text: "Cover available (at an additional cost) for Hire & Reward – Driving Schools / Courier / Chauffeur and Taxi"
      },
      {
        text: "Day one cover of electrical and mechanical (except those stated in section 6 of our policy document). Failure of insured parts due to Wear & Tear starts 60 days after the policy start date"
      },
      {
        text: "Unlimited number of claims up to the purchase price / value of your vehicle"
      },
      {
        text: "Service History not required to purchase cover (Routine servicing is required to be carried out during the policy term)"
      },
      {
        text: "Fully insured product, underwritten by Financial & Legal Insurance Company Limited"
      },
      {
        text: "No Pre-Inspection or Health Check Required"
      },
      {
        text: "No claims excess"
      },
      {
        text: "Use own VAT registered garage or our approved repairer"
      },
      {
        text: "Covers mechanical and electrical failure whilst driving in Europe."
      },
      {
        text: "Contribution of up to 1 hour labour towards diagnostics or reprogramming on an agreed claim"
      },
      {
        text: "£50 per day contribution towards a courtesy car on a valid claim for up to seven days"
      },
      {
        text: "Up to £50 towards the cost of towing the vehicle to the nearest repairer on a valid claim"
      },
      {
        text: "Up to £60 including VAT towards overnight accommodation or a return rail fare if you are unable to return home on a valid claim"
      },
      {
        text: "Choice of labour rates and claim limits"
      }
    ],
    'Upgrades included	in	Gold': [
      {
        title: "Wear & Tear ",
        text: `Our optional upgrade to include Boost offers cover for Wear & Tear” which is
        the expected deterioration or performance of a part due to the cars age or mileage and if a part
        breaks, or fails, due to the mileage or running of the car, it is typically considered as failure due
        to “Wear & Tear". Where a claim is for a covered component, and the claim would be valid
        except for Wear and Tear.`
      },
      {
        title: "Boost",
        text: "Our optional upgrade to include Boost offers cover for:"
      },
      {
        subtitle: "Satellite Navigation System",
        text: "Includes the satellite navigation system in the Vehicle, where part of the manufacturer’s original specification, including the LED/digital display."
      },
      {
        subtitle: "Battery",
        text: "Includes the Vehicle battery (except the drive power battery of hybrid or electrically driven vehicles)."
      },
      {
        subtitle: "In-car entertainment systems / Multimedia / Sound Equipment",
        text: "Includes multimedia/sound equipment, where part of the manufacturer’s original specification, including TV units, LED/Digital display, and remote signal units"
      },
    ],
    'What is not covered - (See Terms & Conditions in section of our policy document for full details)': [

      {
        text: 'Vehicles used for competition purposes, rallying, time trials or racing'
      },
      {
        text: 'Air bags and systems (inc. switches, modules, and control unit)          '
      },
      {
        text: 'Serviceable Parts, servicing, adjusting, or cleaning parts'
      },
      {
        text: 'Wiring harness, wiring terminals, Linkages, cables, lock barrels, keys, key fobs, drop links, window regulators & cables, Auxiliary drive belts, Fuel tank'
      },
      {
        text: 'All bodywork handles and hinges, brackets, mountings, supports, brightwork, paint, glass (including front & rear heated screens & elements), weatherstrips, rubber seals, sheet metal, wiper arms, wheels, fixings and fastening devices, Interior/exterior trim, sunroof panels, carpets, seats and seat belts.'
      },
      {
        text: 'Any claim which is reported to the Administrator more than 14 days after the relevant fault is discovered'
      },
      {
        text: 'Any claim where the repair has not commenced within 14 days of the fault being reported and authorised by the Administrator'
      },
      {
        text: 'Any claim when the vehicle has not been serviced in line with the manufacturer’s guidelines'
      },
      {
        text: 'Any damage caused by: Neglect, abuse, corrosion, freezing, water ingress or any foreign body or fluid, getting into or onto a part; lack of servicing, the effects of over-heating, whether caused by an insured part or not, parts which have been fitted incorrectly; or consequential damage, Faults or defects which are likely to have existed before the start of this warranty'
      },
      {
        text: 'Any pre-existing fault, apparent on the vehicle at the policy start date.'
      },
    ],
    'Key Exclusions - Gold Cover': [
      {
        text: "Catalytic Convertor"
      }
    ],
    'Key Exclusions - Silver Cover': [
      {
        text: "Catalytic Convertor, Diesel Particulate Filter, and wear & tear"
      }
    ],
    'Claim Limits': [
      {
        text: 'Claim Limit - A choice of claim limits from £2,500 to £5,000 per individual claim, up to the purchase price / value of the vehicle at the policy start date.'
      }
    ],
    'Labour Rates': [
      {
        text: "Labour Rates - A choice of Labour rates from £70.00 per hour to £100 per hour"
      }
    ],
    'Want to know more?': [
      {
        text: "In addition to the details above, more information can be found at the providers website"
      }
    ],
    'The legal bit': [
      {
        text: `Alternative Insurance Brokers.com is Authorised and Regulated by the Financial Conduct
        Authority. Our Financial Services Register Number is 602443. You may check this on the FCA
        Register by visiting the FCA’s website, www.fca.org.uk/register or by contacting the FCA on
        0800 111 6768.`
      },
      {
        text: 'Alternative Insurance Brokers Ltd is registered in England No. 8253536'
      }
    ],
    'About the provider': [
      {
        text: `At Alternative Insurance Brokers, we have been working for the past eight years to match people with
        the specialist policies that they need. We work with a wide range of insurers to make sure that we
        always find you the best deals on the market.`
      },
      {
        text: `Our range of products range from the conventional to the unusual, and our selection is always
        growing – with new products available consistently.`
      },
      {
        text: `Our team have a vast amount of experience and can offer you expert advice. Whether you are looking
        to take out a new policy, or you are an existing customer of ours, we are ready to help you with any
        questions or queries you may have about any of our products.
        `
      },
    ],
    'Why use us?': [
      {
        text: 'Peace of mind'
      },
      {
        text: 'Quality protection, at a great price'
      },
      {
        text: 'FCA Regulated & FSCS protected        '
      },
      {
        text: 'All products are fully insured and backed by our panel of Insurers who are all "A" Rated and/or meet "Solvency II" requirements        '
      },
      {
        text: 'Cover from Day One, on selected products        '
      },
      {
        text: 'An expert team of UK based insurance specialists and claims handlers        '
      },
      {
        text: 'On hand whenever you need us'
      },
      {
        text: 'Cover available for 1, 2 or 3 years on most products'
      },
    ]
  },
  invictus: {
    'logo': invictuspng,
    'What do we cover': [
      {
        text: `Invictus Protect insured vehicle warranty (MBI) covers the sudden Mechanical and Electrical
        failure or the vehicle parts, including but not limited to: Engine, Gearbox, Clutch (excluding
        where the condition is due to wear & tear or the clutch is burnt out), Drive Systems, Four Wheel
        Drive System, Wheel Bearings, Fuel System, Cooling System, Air Conditioning, Steering
        System, Suspension System, Braking System (Excluding brake pads, discs, shoes & callipers),
        Electrical System, Entertainment & Communication Systems`
      },
      {
        text: "Cover available for Car or Light Commercial Vehicle (LCV)"
      },
      {
        text: "Elite - (All Mechanical & Electrical cover – only parts listed are excluded from cover) for vehicles up to 10 years old and 100,000 miles at policy inception"
      },
      {
        text: "Merit - (Listed Parts only are covered) for vehicles up to 12 years old and/or 120,000 miles at policy inception"
      },
      {
        text: "Cover available for Car, Motorbike or Motorhome"
      },
      {
        text: "Cover available (at an additional cost) for Hire & Reward – Driving Schools / Courier / Chauffeur and Taxi"
      },
      {
        text: "Day one cover of electrical and mechanical (except those stated in section 6 of our policy document). Failure of insured parts due to Wear & Tear starts 60 days after the policy start date"
      },
      {
        text: "Unlimited number of claims up to the purchase price / value of your vehicle"
      },
      {
        text: "Service History not required to purchase cover (Routine servicing is required to be carried out during the policy term)"
      },
      {
        text: "Fully insured product, backed by an “A” rated insurer – Fortegra Europe Insurance Company Ltd"
      },
      {
        text: "No Pre-Inspection or Health Check Required"
      },
      {
        text: "No claims excess"
      },
      {
        text: "Use any VAT registered garage or our approved repairer"
      },
      {
        text: "Covers mechanical and electrical failure whilst driving in Europe"
      },
      {
        text: "Contribution towards diagnostics or reprogramming on an agreed claim"
      },
      {
        text: "£50 per day contribution towards a courtesy car on a valid claim for up to seven days"
      },
      {
        text: "Up to £50 towards the cost of towing the vehicle to the nearest repairer on a valid claim"
      },
      {
        text: `Up to £60 including VAT towards overnight accommodation or a return rail fare if you are unable
        to return home on a valid claim`
      },
      {
        text: "Choice of labour rates and claim limits"
      }
    ],
    'Optional Upgrades': [
      {
        title: "Wear & Tear ",
        text: `Our optional upgrade to include Boost offers cover for “Wear & Tear” which is
        the expected deterioration or performance of a part due to the cars age or mileage and if a part
        breaks, or fails, due to the mileage or running of the car, it is typically considered as failure due
        to “Wear & Tear". Where a claim is for a covered component, and the claim would be valid
        except for Wear and Tear. Wear and Tear claims (apart from burnt or worn-out clutch parts),
        will be valid under the Warranty, after the initial 60-day exclusion period.`
      },
      {
        title: "Boost",
        text: "Our optional upgrade to include Boost offers cover for:"
      },
      {
        subtitle: "Satellite Navigation System",
        text: `- Includes the satellite navigation system in the Vehicle, where
        part of the manufacturer’s original specification, including the LED/digital display.`
      },
      {
        subtitle: "Battery",
        text: `- Includes the Vehicle battery (except the drive power battery of hybrid or electrically
          driven vehicles).`
      },
      {
        subtitle: "Diesel Particulate Filter",
        text: "Includes blocked, contaminated, or damaged filters."
      },
      {
        subtitle: "In-car entertainment / Multimedia/Sound Equipment",
        text: `Includes multimedia/sound
        equipment, where part of the manufacturer’s original specification, including TV units,
        LED/Digital display, and remote signal units (Claim limit of £1,000 on this section).`
      },
      {
        subtitle: "Catalytic Converter",
        text: "Includes the catalytic converter, where blocked, contaminated, or damaged"
      }
    ],
    'What is not covered - (See Terms & Conditions in section 6 of our policy document for full details)': [

      {
        text: 'Vehicles used for competition purposes, rallying, time trials or racing'
      },
      {
        text: 'Air bags and systems (inc. switches, modules, and control unit)          '
      },
      {
        text: 'Serviceable Parts, servicing, adjusting, or cleaning parts'
      },
      {
        text: 'Wiring harness, wiring terminals, Linkages, cables, lock barrels, keys, key fobs, drop links, window regulators & cables, auxiliary drive belts, fuel tank'
      },
      {
        text: 'All bodywork handles and hinges, brackets, mountings, supports, brightwork, paint, glass (including front & rear heated screens & elements), weatherstrips, rubber seals, sheet metal, wiper arms, wheels, fixings and fastening devices, Interior/exterior trim, sunroof panels, carpets, seats and seat belts.'
      },
      {
        text: 'Any claim which is reported to the Administrator more than 14 days after the relevant fault is discovered'
      },
      {
        text: 'Any claim where the repair has not commenced within 14 days of the fault being reported and authorised by the Administrator'
      },
      {
        text: 'Any claim when the vehicle has not been serviced in line with the manufacturer’s guidelines'
      },
      {
        text: 'Any damage caused by: Neglect, abuse, corrosion, freezing, water ingress or any foreign body or fluid, getting into or onto a part; lack of servicing, the effects of over-heating, whether caused by an insured part or not, parts which have been fitted incorrectly; or consequential damage, Faults or defects which are likely to have existed before the start of this warranty'
      },
      {
        text: 'Any pre-existing fault, apparent on the vehicle at the policy start date.'
      },
    ],
    'Key Exclusions - with Boost and Wear & Tear': [
      {
        text: "Wear & Tear in the first 60 days"
      },
      {
        text: "Vehicle Turbo"
      }
    ],
    'Key Exclusions - without Boost and Wear & Tear': [
      {
        text: "Catalytic convertor, Diesel Particulate Filter, Satellite Navigation System, In-car entertainment, and Wear & Tear"
      },
    ],
    'Claim Limits': [
      {
        text: 'Claim Limit - A choice of claim limits from £2,500 to £5,000 per individual claim, up to the purchase price / value of the vehicle at the policy start date.'
      }
    ],
    'Labour Rates': [
      {
        text: "Labour Rates - A choice of Labour rates from £70 per hour to £100 per hour"
      }
    ],
    'Want to know more?': [
      {
        text: "In addition to the details above, more information can be found at the providers website"
      }
    ],
    'The legal bit': [
      {
        text: `Invictus Protect.com is an Appointed Representative of Rest Ashored Ltd who are Authorised
        and Regulated by the Financial Conduct Authority. Our Financial Services Register Number is
        732530. You may check this on the FCA Register by visiting the FCA’s website,
        www.fca.org.uk/register or by contacting the FCA on 0800 111 6768.`
      },
      {
        text: 'Rest Ashored Ltd is registered in England No. 08179370'
      }
    ],
    'About the provider': [
      {
        text: 'Invictus Protect - Competitive, quality vehicle protection products – all under one roof!'
      },
      {
        text: `Invictus Protect offer a full range of Insurance backed products to protect your vehicle
        including warranty, GAP, Alloy Wheel Insurance and Cosmetic Repair Insurance.
        With over 30 years Motor Industry experience and an A-Rated Insurer you can be confident in
        buying an insurance product from us.
        All of our products are backed by an insurance policy to give you the confidence provided by
        the FCA and FSCS.`
      },
    ],
    'Why use us?': [
      {
        text: 'Peace of mind'
      },
      {
        text: 'Quality protection, at a great price'
      },
      {
        text: 'FCA Regulated & FSCS protected        '
      },
      {
        text: 'All products are fully insured and backed by our panel of Insurers who are all "A" Rated insurers        '
      },
      {
        text: 'Cover from Day One, on selected products, including Wear & Tear on our Warranty Product        '
      },
      {
        text: 'An expert team of UK based insurance specialists and claims handlers        '
      },
      {
        text: 'Over 30 years of experience        '
      },
      {
        text: 'On hand whenever you need us'
      },
      {
        text: 'Cover available for 1, 2 or 3 years on most products'
      },
      {
        text: 'Providing product protection to over £100 million worth of vehicles across the UK'
      },
    ]
  }
}

export const tableInformations = {

  thead: [
    {
      title: "RUBY",
      subtitle: "Includes £6,000 cancellation cover",
    },
    {
      title: "SAPPHIRE",
      subtitle: "Includes £10,000 cancellation cover",
    },
    {
      title: "EMERALD",
      subtitle: "Includes £15,000 cancellation cover",
    },
    {
      title: "DIAMOND",
      subtitle: "Includes £20,000 cancellation cover",
    },
    {
      title: "PLATINUM",
      subtitle: "Includes £30,000 cancellation cover",
    },

  ],
  rows: [
    {
      row: [
        {
          title: "Price",
          subtitle: " from IPT at 12%",
        },
        {
          title: "£50",
          subtitle: "Buy Now",
        },
        {
          title: "£80",
          subtitle: "Buy Now",
        },
        {
          title: "£99",
          subtitle: "Buy Now",
        },
        {
          title: "£110",
          subtitle: "Buy Now",
        },
        {
          title: "£150",
          subtitle: "Buy Now",
        }
      ]

    },
    {
      row: [
        {
          title: "Cancellation, Curtailment & Rearrangement*",
        },
        {
          title: "£5,000",
        },
        {
          title: "£10,000",
        },
        {
          title: "£15,000",
        },
        {
          title: "£20,000",
        },
        {
          title: "£50,000",
        }
      ]

    },
    {
      row: [
        {
          title: "Financial Failure of Wedding Services Suppliers",
        },
        {
          title: "£2,000",
        },
        {
          title: "£3,000",
        },
        {
          title: "£4,000",
        },
        {
          title: "£5,000",
        },
        {
          title: "£15,000",
        }
      ]
    },
    {
      row: [
        {
          title: "Ceremonial Attire",
        },
        {
          title: "£1,000",
        },
        {
          title: "£2,000",
        },
        {
          title: "£3,000",
        },
        {
          title: "£4,000",
        },
        {
          title: "£8,000",
        }
      ]
    },
    {
      row: [
        {
          title: "Photography & Video",
        },
        {
          title: "£1,000",
        },
        {
          title: "£2,000",
        },
        {
          title: "£3,000",
        },
        {
          title: "£4,000",
        },
        {
          title: "£8,000",
        }
      ]
    },
    {
      row: [
        {
          title: "Wedding Rings, Flowers, Gifts and Cake",
        },
        {
          title: "£1,000",
        },
        {
          title: "£2,000",
        },
        {
          title: "£3,000",
        },
        {
          title: "£4,000",
        },
        {
          title: "£8,000",
        }
      ]
    },


  ]
}



export const faqInformations = {

  "MBI": [
    {
      title: "Frequently asked questions",
      //description: "Tie the knot with "+MyTheme.pageTitle+" to gift yourself ultimate peace of mind for your truly special occasion.\n We vow to keep your investment safe and carry you over the financial threshold in the event of any unwanted interruptions to your big day.",
      label: "Purchase Cover →",
      href: "/checkout",
      questions: [
        {
          question: "What is the maximum age that my vehicle can be to qualify for a " + MyTheme.pageTitle + " warranty?",
          answers: [
            {

              answer: "If your vehicle is a car or light commercial vehicle, then it must be no more than 120 months old (from date of DVLA original registration) and not have covered more than 100,000 miles at the time of policy purchase."
            },
            {
              answer: "If your vehicle is a motorcycle, then it must be no more than 96 months old (from date of DVLA original registration) and not have covered more than 70,000 miles at the time of policy purchase "
            },
            {
              answer: "If your vehicle is a motorhome, then it must be no more than 60 months old (from date of DVLA original registration) and not have covered more than 60,000 miles at the time of policy purchase."
            }

          ]

        },
        {
          question: "How long will my " + MyTheme.pageTitle + " vehicle warranty last?",
          answers: [
            {
              answer: "You can buy an extended warranty for periods of 12, 24 or 36 months.",
            }
          ]
        },
        {
          question: "Can I spread the costs of the " + MyTheme.pageTitle + " vehicle warranty?",
          answers: [
            {
              answer: "You can. " + MyTheme.pageTitle + " will allow you to spread the costs of your warranty over 12 equal monthly payments.",
            }
          ]
        },
        {
          question: "If my vehicle breaks down on holiday, how do I claim?",
          answers: [
            {
              answer: "Please do not worry, our claims process is the same as if you were in the UK. The only difference being that you will have to pay for any costs, directly to the repairer. On your return, you will then reclaim our authorised and agreed claim amount from us.",
            }
          ]
        },
        {
          question: "Can I make a claim out of hours?",
          answers: [
            {
              answer: "Yes. You can register any warranty claim with us on our “Out of hours” message service but we cannot approve any potential claims until we re-open. Please ensure that you DO NOT authorise the repairer to start any work on your vehicle until we have agreed the claim.",
            }
          ]
        },
        {
          question: "What happens if I sell my vehicle?",
          answers: [
            {
              answer: "If you decide to sell your vehicle before your " + MyTheme.pageTitle + " warranty has expired, you can request that we transfer any remaining period to the new owner. There would be an administration fee payable, should we agree to the transfer.",
            }
          ]
        },
        {
          question: "Do I need to pay any excess?",
          answers: [
            {
              answer: "No. There is no policy excess.",
            }
          ]
        },
        {
          question: "Is there any additional cost for the make or model of vehicle, or if I have a 4×4 vehicle?",
          answers: [
            {
              answer: "No. We only rate our warranty prices based on if the engine size and it`s age, so you will not be charged more just because you own a prestige make of vehicle.",
            }
          ]
        },
        {
          question: "What does Boost cover include?",
          answers: [
            {
              answer: "Our Boost cover includes the Battery, Catalytic Convertor, Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
            }
          ]
        },
        {
          question: "Do I need to pay for my repairs?",
          answers: [
            {
              answer: "No. If you use one of our approved repairers, we will pay them on your behalf meaning that you do not need to pay and then reclaim from us.",
            }
          ]
        },
        {
          question: "Can I buy your warranty now and start it when my manufacturer warranty expires?",
          answers: [
            {
              answer: "Yes. Your " + MyTheme.pageTitle + " warranty will start from the date commencing immediately after expiry of the manufacturers’ existing warranty period.",
            }
          ]
        },
        {
          question: "Can I transfer my warranty policy?",
          answers: [
            {
              answer: "Yes. You have two options available if you sell your vehicle."
            },
            {
              answer: "If you have sold your vehicle to a private buyer, you can transfer the unused portion of your warranty to the new owners - subject to our approval, and a payment of an administration fee. We cannot transfer the policy if you sell your vehicle to a motor dealer."
            },
            {
              answer: "Alternatively, if you have purchased a replacement vehicle, the unused warranty term can be transferred to the replacement vehicle. This can be done with us by phone or email.",
            }
          ]
        },


      ]
    }
  ],
  "Wedding": [
    {
      title: "Frequently asked questions",
      description: "Tie the knot with " + MyTheme.pageTitle + " to gift yourself ultimate peace of mind for your truly special occasion.\n We vow to keep your investment safe and carry you over the financial threshold in the event of any unwanted interruptions to your big day.",
      label: "Purchase Cover →",
      href: "/wedding-checkout",
      questions: [
        {
          question: "Is wedding insurance paid monthly?",
          answers: [
            {
              answer: "No, wedding insurance is just a one-off payment, showing what excellent value for money it is.",
            }
          ]
        },
        {
          question: "When should we take out wedding insurance?",
          answers: [
            {
              answer: "Ideally, you should take it out as soon as you have paid any deposits or signed a contract that means you will lose money if you are forced to change your plans. You must purchase this more than 14 days in advance of the wedding date.",
            }
          ]
        },
        {
          question: "Who can buy wedding insurance?",
          answers: [
            {
              answer: "Anyone can buy a wedding insurance policy, for instance parents of the bride or groom, but the policy must be in the name of the two people who are getting married as it is their wedding being covered.",
            }
          ]
        },
        {
          question: "I have already paid a deposit. Will this be covered?",
          answers: [
            {
              answer: "Yes - provided you are not aware of any particular circumstances that may give rise to a claim when you take out the policy.",
            }
          ]
        },
        {
          question: "Does wedding insurance cover relative's money?",
          answers: [
            {
              answer: `Our wedding insurance will cover money paid by relatives of the couple, providing they have a demonstrable financial interest, such as a receipt or booking confirmation. \n
        
              All claims will have to be submitted by the insured couple and then the settlement passed on to the person who paid.`,
            }
          ]
        },
        {
          question: "My fiancé lives abroad. Can we take out a policy?",
          answers: [
            {
              answer: "Yes - provided one of you is normally resident here in the UK you can take out a policy.",
            }
          ]
        },
        {
          question: "My fiancé is in the Armed Forces. Are we covered if he is unexpectedly posted abroad?",
          answers: [
            {
              answer: "Yes - provided that there was no notice of possible cancellation of leave at the time the policy was taken out.",
            }
          ]
        },
        {
          question: "What about if one of us decides not to go ahead with the marriage after all?",
          answers: [
            {
              answer: `This is known as "disinclination to proceed" and is not an insured reason for cancellation, sadly. However, you may be able to claim for cost of professional counselling under Section 9 of the policy.`,
            }
          ]
        },
        {
          question: "Is family bereavement covered by wedding insurance?",
          answers: [
            {
              answer: "Cancellation or rearrangement due to family bereavement is covered under section 1 of our wedding insurance policy if it makes going ahead with the wedding inappropriate. However, you must answer the medical declaration for the policy in respect of all people on whom your wedding plans depend before you purchase the policy. If you answer yes to any of the medical questions with regard to a relative, you will not be covered in relation to that medical condition. You will of course be covered for anything unrelated to that relative's condition.If you are in doubt please contact us on 0161 505 1420, before you purchase the policy.",
            }
          ]
        },
        {
          question: "What happens if the professional wedding photographs fail because the film or digital storage media is faulty and there are no copies available?",
          answers: [
            {
              answer: "Then the reasonable costs of taking replacement formal photographs of the couple and the immediate Wedding party can be claimed.",
            }
          ]
        },
        {
          question: "I have an existing medical condition. Can this be covered?",
          answers: [
            {
              answer: "Most existing medical conditions are insured automatically, both for the Couple and also their close relatives. There is no cover, however, for Pre-existing medical conditions that have resulted in a terminal prognosis or where the condition is awaiting treatment or investigation at a hospital or you are awaiting the results of tests or investigations, unless you have declared details to us and we have agreed to insure them. You can call us to discuss a medical condition on 0161 505 1420.",
            }
          ]
        },
        {
          question: "What happens if one of the suppliers goes bust?",
          answers: [
            {
              answer: "Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.",
            }
          ]
        },
        {
          question: "Our wedding venue is flooded just before the big day. Are we covered?",
          answers: [
            {
              answer: "Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.",
            }
          ]
        },
        {
          question: "Our venue requires us to provide insurance in case any of us or the guests causes accidental damage during the reception. Is this included?",
          answers: [
            {
              answer: "The marrying couple’s Public liability is included as standard up to £2m. There is an optional extension available to add cover for the liabilities of your wedding party up to £2m in total. This can be further increased to be £5m with the additional extension.",
            }
          ]
        },
        {
          question: "Is my honeymoon or overseas wedding trip insured on this policy?",
          answers: [
            {
              answer: "No - you will need to take out separate travel insurance for your honeymoon or overseas wedding trip, which would normally include cancellation cover if you are not able to travel as planned.",
            }
          ]
        },
        {
          question: "Does the wedding insurance policy have an excess?",
          answers: [
            {
              answer: "Yes, there is an excess of £100 applicable under all sections of the policy, except for the Public Liability sections (sections 12, 13 & 14) which have an excess of £250.",
            }
          ]
        },
      ],
    },
  ]
}

export const paragraphImage = {

  "Alloy Wheel": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Pristine alloy wheels without the cost of repair",
      description: "Scraped the curb? It’s okay. We’ve got you. With this Alloy Wheel product, you’re covered for up to £200 per claim, and up to £2,000 in Aggregate.",
      label: "What's covered →",
      tableTitle: "A range of cover options",
      tableDescription: [
        {
          title: '18" & BELOW',
          term: "12, 24, 36 Months",
          claim_limit: "£100",
          ppid: "23",
        },
        {
          title: 'OVER 18"',
          term: "12, 24, 36 Months",
          claim_limit: "£200",
          ppid: "23",
        },
        {
          title: 'ANY DIAMOND CUT & POLISHED',
          term: "12, 24, 36 Months",
          claim_limit: "£200",
          ppid: "23",
        }
      ],
    }


  ],
  "Wedding": [
    {
      // add key "image" to add  image insetead of table: e.g. "image": cosmetic_insurance
      title: "Don't leave anything to chance",
      description: "Weddings and civil ceremonies are a special cause for celebration; a day you will remember for the rest of your life. We know they can cost a lot, both financially and emotionally. We also understand how stressful it can be if something doesn’t quite go how you planned it. That’s why at " + MyTheme.pageTitle + ", we don’t leave anything to chance, offering Wedding Insurance to give you the peace of mind on your big day.",
      label: "What's Covered →",
      image: wedding_insurance_paragraph
    }
  ],
  "Tyre": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Cover all replacement tyre costs",
      description: "Don’t let a puncture stop you in your tracks. Whether you’ve driven over a nail, or made a new enemy (don’t worry, we’re not one to pry!), our policy covers you for both accidental and malicious damage.",
      label: "What's covered →",
      tableTitle: "A range of cover options",
      tableDescription: [
        {
          title: '18" & BELOW',
          term: "12, 24, 36 Months",
          claim_limit: "£250",
          ppid: "23",
        },
        {
          title: 'OVER 18"',
          term: "12, 24, 36 Months",
          claim_limit: "£250",
          ppid: "23",
        },
        {
          title: 'RUN FLAT',
          term: "12, 24, 36 Months",
          claim_limit: "£250",
          ppid: "23",
        }
      ],
    }


  ],
  "Cosmetic Repair": [
    {
      // add key "image" to add  image insetead of table: e.g. "image": cosmetic_insurance
      title: "Great Value Cosmetic/SMART Repair",
      description: "Drive the car you love without the hassle of damage costs thanks to " + MyTheme.pageTitle + "’s cosmetic repair insurance, providing cover for minor dents, stone chips, scratches and scuffs.",
      label: "What's covered →",
      image: cosmetic_paragraph_image
    }


  ],
  "Excess": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Say no to excess - cover all eventualities",
      description: "Cover yourself in all situations with " + MyTheme.pageTitle + "’s excess car insurance, designed to keep unnecessary bills away in the event of making a claim.",
      label: "What's covered →",
      image: excess_insurance_paragraph
    }


  ],
  "Finance Gap": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "Don’t get caught short",
      description: "When we buy our vehicles, the last thing we ever imagine is the possibility it may be written off. That’s why many vehicle owners are left out of pocket when the unthinkable happens, as all insurers are only legally obliged to compensate the current market value of your vehicle. GAP Insurance from " + MyTheme.pageTitle + " provides protection against financial loss that may incur as a result of your vehicle being a total write-off due to accident, fire, vandalism, flood or theft.",
      label: "What's covered →",
      image: gap_insurance_paragraph
    }

  ],
  "MBI": [
    {
      // add key "image" to add  image instead of table: e.g. "image": cosmetic_insurance
      title: "It’s time to eliminate expensive bills",
      description: "With " + MyTheme.pageTitle + ", you can rest easy, even if your car lets you down. Because we can cover up to 100% of your breakdown repair costs, so you don’t get caught with an unexpected bill. Mechanical and electrical failures can happen, and do happen. So get covered, and let " + MyTheme.pageTitle + " cushion any unforeseen blows with secure and reliable breakdown insurance.",
      label: "What's covered →",
      image: warranty_insurance_paragraph
    }

  ],

}

export const aboutUsPageInfo = [

  {
    title: `Decades of Experience `,
    description: `Just think how good you’d be at something if you’d been doing it for over 20 years? 
Our team of car-lovers have been saving people money and time for decades, using 
their experience and knowledge across all kinds of insurance markets in the UK and 
across Europe. 
That’s why we know how to get the best deal for you and your vehicle.
We’ve used all that knowledge to create a helpful range of policies to keep you fully 
covered.`,
    image: aboutUsExperience,
  },
  {
    title: `We Believe in Giving Back`,
    description: `Responsibility is an important word to us here at ` + MyTheme.pageTitle + `. 
Not only are we responsible for your car’s every need, but we do our part in being 
responsible for nurturing our planet. 
That’s why for every policy sold, we plant one tree. 
If you think about it, that’s A LOT of trees. 
Especially when you consider our policy prices. 
Want to help plant a tree today? 
`,
    image: giving,
  },
];

export const arrayOfOrigins = [
  "Alloy Wheel",
  "Return to Invoice",
  "Finance Gap",
  "Tyre",
  "MBI",
  "Cosmetic Repair",
  "Excess",
];



export const cardDetails = [
  {
    origin: "Alloy Wheel",
    title: "Alloy Insurance",
    description: `Scraped the curb? It’s okay. We’ve got you. With this Alloy Wheel product, you’re covered for up to £200 per claim, and up to £2,000 in Aggregate. `,
    image: alloyInsurance,
  },
  {
    origin: "Return to Invoice",
    title: "GAP Insurance",
    description: `Having your vehicle accidentally damaged, set on fire or stolen is bad enough, right? Now imagine being hit with a huge bill on top of that? Instead, cover-up with our affordable combined GAP and RTI. `,
    image: gapInsurance2,
  },
  {
    origin: "Finance Gap",
    title: "GAP Insurance",
    description: `Having your vehicle accidentally damaged, set on fire or stolen is bad enough, right? Now imagine being hit with a huge bill on top of that? Instead, cover-up with our affordable combined GAP and RTI. `,
    image: gapInsurance2,
  },
  {
    origin: "Tyre",
    title: "Tyre Insurance",
    description: `Don’t let a puncture stop you in your tracks. Whether you’ve driven over a nail, or made a new enemy (don’t worry, we’re not one to pry!). `,
    image: tyreInsurance,
  },
  {
    origin: "MBI",
    title: "Vehicle Warranty",
    description: `We’ve all been there, and there’s no way of sugar-coating it. Your vehicle breaking down due to mechanical or electrical failure is stressful. But if it ever happens to you, be thankful you signed up for our ` + MyTheme.pageTitle + ` Warranty, and stress no more. `,
    image: mbi,
  },
  {
    origin: "Cosmetic Repair",
    title: "Cosmetic Repair Insurance",
    description: `Our cosmetic repair policy is as cheap as, well… chips! Seriously, never worry again about dents, scratches, scuffs or even stone chips.   We’ll have you covered for any claim up to £250, with a generous aggregate claim limit of £3,000.`,
    image: cosmeticInsurance,
  },
  {
    origin: "Excess",
    title: "Excess Insurance",
    description: `We know it’s not your fault if somebody damages your vehicle and you have to make a motor insurance claim.
That’s why we want to minimise your expense by covering any motor excess up to your claim limit.`,
    image: mbi,
  },
];

export const couchSectionHomepage = {

  title: `We drive insurance prices down`,
  description: `We love our vehicles, but we also understand how much of a pain and burden they can be when the unexpected happens. At ` + MyTheme.pageTitle + `, it’s our job to expect the unexpected, providing you with complete, comprehensive cover, to ease your stresses away.`,
  label: "Get A Quote →",
  link: "/checkout",
  reasonTitle: `Why choose us`,
  reasons: [
    `Peace of mind`,
    `Quality protection, at a great price`,
    `Cover from Day One, on selected products`,
    `An expert team of insurance specialists`,
    `Over 20 years of experience`,
    `On hand whenever you need us`,
    `Cover available for 1, 2 or 3 years on most products`,
    `You even get to help nurture the environment`
  ],
  labelReason: "Find out more",
  linkReason: "/checkout",
}

export const landingGuide =
  {
    title: `Things you should check on the warranty quote`,
    benefits: [
      {
        title: "Level of component cover",
        description: "– Most warranties will offer you parts and labour cover from day one, but with various levels of parts coverage, claim limits, labour rates, and maybe even whether to include wear and tear or not. Before buying, check the policy wording to make sure you are getting the level of cover you need for your vehicle."
      },
      {
        title: "Individual Limit Per Claim",
        description: "- Don’t forget to compare the maximum amount of cover per claim."
      },
      {
        title: "Aggregate Claim Limit",
        description: "- The total amount you can claim during the life of the policy you have selected - this is normally the purchase price or value of your vehicle on the policy inception date. Some warranty providers may reduce this amount in line with the vehicle depreciation."
      },
      {
        title: "Policy Excess",
        description: "- The defined amount you agree to pay towards any future claim. Some providers charge an excess and some do not."
      },
      {
        title: "Wear and Tear and Boost Upgrade",
        description: "Wear and Tear and Boost is an optional increase in the level of cover provided at an additional cost. These are:"
      },
      {
        title: "Failures caused by overheating",
        description: "- Overheating can cause enormous damage to your vehicle, yet some warranties exclude it. Check your policy wording to see if it includes overheating caused by failure of other components, and/or overheating causing damage to components."
      },
      {
        title: "Day One Cover",
        description: "- Check to see if the warranty cover starts with immediate effect or if there is a waiting period or if an inspection or vehicle health check is required before cover is in place."
      },
      {
        title: "Paying in full for your policy",
        description: "- If you elect to pay the full policy cost upfront, this must be paid before the warranty starts."
      },
      {
        title: "Payment by monthly Instalments",
        description: "- If you elect to pay for the policy by way of monthly installments, this must be arranged at time of the warranty purchase. This is usually by way of 6/10/12 equal monthly instalments but check the APR % being charged or if there is an administration fee and the number of payments you need to make. Also be aware that your warranty may end if you default on any payments, but you will still be liable for any balance due on the premium finance agreement."
      },
      {
        title: "Cancellation",
        description: "- If you decide to cancel your warranty purchase within the first 30 days, check to see what the fees / charges you may still be liable for. If you cancel after the first 30 days, you will probably not get a refund of any premium paid. Please be aware that you will probably not get any refund if you have made a claim, even if it was declined."
      },
      {
        title: "Labour rate",
        description: "- Many warranty providers use different labour rates that they will pay up to for a repair under the warranty on a successful  claim. You should check to see what labour rate is being offered on any warranty quote, with many warranty providers  offering a lower labour rate if you choose your own repairer, rather than using one of their approved repairers"
      },
      {
        title: "Warranty Transfer",
        description: "- If you sell the vehicle covered by a warranty you purchase, you may be able to transfer the remaining period to a replacement vehicle you purchase. Check to see if a transfer is allowed and how much you may be charged to do so."
      },
      {
        title: "Full-service history"
      }
    ],
  }
  


export const finalStepVehicleCheckout = [
  {
    title: "Wedding Insurance",
    subtitle: "Don't leave anything to chance",
    benefits: [
      "Competitive rates",
      "Comprehensive cover",
      "A range of cover options"
    ],
    imagePNG: weddingBackgroundPng,
    imageWEBP: weddingBackgroundWebp,
    link: '/wedding-checkout'

  }
]


export const insuranceCard = [
  {
    title: "GAP Insurance",
    subtitle: "Don't get caught short",
    benefits: [
      "Competitive rates",
      "Comprehensive cover",
      "Protect against depreciation",
    ],
    imagePNG: newImg,
    imageWEBP: newImgwebp,
    link: '/checkout'
  },
  {
    title: "Vehicle Warranty",
    subtitle: "Eliminate expensive bills",
    benefits: [
      "Up to 100% cover of your breakdown costs",
      "Cheaper than the cost of repair",
      "Secure & reliable insurance",
    ],
    image: newImg2,
    imagePNG: newImg2,
    imageWEBP: newImg2Webp,
    link: '/checkout'

  },
]


export const tyreEligible = [
  {
    title: "Am I eligible for tyre insurance?",
    description: `We aim to make tyre insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you.`,
    benefitsList: "Vehicle purchased",
    benefitsListCont: "within 90 days",
    criteria: "Vehicle purchased",
    criteriaCont: "over 90 days ago",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },

      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },

    ],

    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. This email will also provide instructions for having your alloy wheels inspected. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
]

export const benefits = [
  {
    origin: "Finance Gap",
    title: "Am I eligible for GAP insurance?",
    description:
      "We aim to make GAP insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please do not hesitate to contact us.",
    benefitsList: "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    pinkPig: "yes",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "/checkout",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 10 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 120,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },

      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },

    ],
  },
  {
    origin: "Tyre",
    title: "Am I eligible for tyre insurance?",
    description:
      "We aim to make tyre insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you.",
    benefitsList: "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    pinkPig: "yes",
    benefitsListItems: [
      {
        itemList: "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList: "The vehicle has covered less than 80,000 miles when you purchase cover."
      },
      {
        itemList: "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman. ",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },
    ],


    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
  {
    origin: "Alloy Wheel",
    title: "Am I eligible for alloy wheel insurance?",
    description: `We aim to make alloy wheel insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you.`,
    benefitsList: "Vehicle purchased",
    benefitsListCont: "within 90 days",
    criteria: "Vehicle purchased",
    criteriaCont: "over 90 days ago",
    pinkPig: "yes",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList:
          "The vehicles alloy wheels are free from damage when you purchase cover.",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },
      {
        itemList:
          "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc.",
      },
      // {
      //   itemList:
      //     "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      // },
      // {
      //   itemList: "The vehicle is registered and kept principally in the UK",
      // },
    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "/checkout",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. This email will also provide instructions for having your alloy wheels inspected. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
      //   {
      //     itemList:
      //       "The vehicle is less than 7 years old when you purchase cover",
      //   },
      //   {
      //     itemList:
      //       "The vehicle has covered less than 80,000 miles when you purchase cover",
      //   },
      //   {
      //     itemList:
      //       "The vehicles alloy wheels are free from damage when you purchase cover",
      //   },
      //   {
      //     itemList:
      //       "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc",
      //   },
      //   {
      //     itemList:
      //       "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      //   },
      //   {
      //     itemList: "The vehicle is registered and kept principally in the UK",
      //   },
    ],
  },
  {
    origin: "Excess",
    title: "Am I eligible for motor excess insurance?",
    description: `We aim to make Excess insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please do not hesitate to contact us.`,
    benefitsList:
      "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    bluePig: "yes",
    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },

      {
        itemList: "The vehicle is registered and kept principally in the UK.",
      },

    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "/checkout",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
  {
    //property name is the last word from smh website in faq MBI
    // origin: "MBI",
    // title: "Am I eligible for vehicle warranty insurance?",
    // description: `We aim to make vehicle warranty insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you.`,
    // benefitsList:
    //   "Vehicle purchased within 90 days",
    // criteria: "Vehicle purchased over 90 days ago",
    // pinkPig: "yes",
    // benefitsListItems: [
    //   {
    //     itemList:
    //       "The vehicle is less than 7 years old when you purchase cover.",
    //   },
    //   {
    //     itemList:
    //       "The vehicle has covered less than 80,000 miles when you purchase cover.",
    //   },
    //   {
    //     itemList:
    //       "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
    //   },
    //   {
    //     itemList: "The vehicle is registered and kept principally in the UK.",
    //   },
    // ],
    // // btnPurchase: "PURCHASE COVER",
    // // label: "/checkout",
    // criteriaListItems: [
    //   {
    //     itemList:
    //       "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
    //   },
    // ],
    warranty: "Can I spread the costs of the " + MyTheme.pageTitle + " vehicle warranty?",
    warrantyListItems: [
      {
        itemList:
          "You can. " + MyTheme.pageTitle + " will allow you to spread the costs of your warranty over 12 equal monthly payments.",
      },
    ],
    holiday:
      "What happens if my vehicle breaks down whilst I am away on holiday?",
    holidayListItems: [
      {
        itemList:
          "You can. " + MyTheme.pageTitle + " will allow you to spread the costs of your warranty over 12 equal monthly payments.",
      },
    ],
    claim: "If my vehicle breaks down on holiday, how do I claim?",
    claimListItems: [
      {
        itemList:
          "Please do not worry, our claims process is the same as if you were in the UK. The only difference being that you will have to pay for any costs, directly to the repairer. On your return, you will then reclaim our authorised and agreed claim amount from us.",
      },
    ],
    hours: "Can I make a claim out of hours?",
    hoursListItems: [
      {
        itemList:
          "Yes. You can register any warranty claim with us on our “Out of hours” message service but we cannot approve any potential claims until we re-open. Please ensure that you DO NOT authorise the repairer to start any work on your vehicle until we have agreed the claim.",
      },
    ],
    Vehicle: "What happens if I sell my Vehicle?",
    VehicleListItems: [
      {
        itemList:
          "If you decide to sell your vehicle before your " + MyTheme.pageTitle + " warranty has expired, you can request that we transfer any remaining period to the new owner. There would be an administration fee payable, should we agree to the transfer.",
      },
    ],
    excess: "Do I need to pay any excess?",
    excessListItems: [
      {
        itemList: "No. There is no policy excess.",
      },
    ],
    vehicle:
      "Is there any additional cost for the make or model of vehicle, or if I have a 4×4 vehicle?",
    vehicleListItems: [
      {
        itemList:
          "No. We only rate our warranty prices based on if the engine size and it`s age, so you will not be charged more just because you own a prestige make of vehicle.",
      },
    ],
    include: "What does Boost cover include?",
    includeListItems: [
      {
        itemList:
          "Our Boost cover includes the Battery, Catalytic Convertor, Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
      },
    ],
    repairs: "Do I need to pay for my repairs?",
    repairsListItems: [
      {
        itemList:
          "No. If you use one of our approved repairers, we will pay them on your behalf meaning that you do not need to pay and then reclaim from us.",
      },
    ],
    expires:
      "Can I buy your warranty now and start it when my manufacturer warranty expires?",
    expiresListItems: [
      {
        itemList:
          "Yes. Your " + MyTheme.pageTitle + " warranty will start from the date commencing immediately after expiry of the manufacturers’ existing warranty period.",
      },
    ],
    policy: "Can I transfer my warranty policy?",
    policyListItems: [
      {
        itemList:
          "Yes. You have two options available if you sell your vehicle.",
      },
      {
        itemList:
          "If you have sold your vehicle to a private buyer, you can transfer the unused portion of your warranty to the new owners - subject to our approval, and a payment of an administration fee. We cannot transfer the policy if you sell your vehicle to a motor dealer.",
      },
      {
        itemList:
          "Alternatively, if you have purchased a replacement vehicle, the unused warranty term can be transferred to the replacement vehicle. This can be done with us by phone or email.",
      },
    ],
  },
  {
    origin: "Cosmetic Repair",
    title: "Am I eligible for cosmetic repair insurance?",
    description: `We aim to make alloy wheel insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please don’t hesitate to `,
    benefitsList: "Vehicle purchased within 90 days",
    criteria: "Vehicle purchased over 90 days ago",
    pinkPig: "yes",
    // levelOfCover: `LEVEL OF COVER:`,
    // levelOfCoverListItems: [
    //   {
    //     itemList: `Minor Dent means damage to a metal body panel, not exceeding 30cm in diameter and where such panel has not been ripped, perforated or torn`,
    //   },
    //   {
    //     itemList: `Chip means damage to the painted vehicle not exceeding 1.5mm in diameter.`,
    //   },
    //   {
    //     itemList: `Light Scratch means damage to the vehicle body panel not exceeding 30cm in length and not extended over more than 2 (two) adjacent body panels.`,
    //   },
    //   {
    //     itemList: `Scuff(s) means light scraping of the top surface paint, not penetrating to the base material of the Vehicle and not exceeding 30cm in diameter.`,
    //   },
    // ],
    benefitsListItems: [
      {
        itemList: "The vehicle is less than 7 years old when you purchase cover.",
      },
      {
        itemList: "The vehicle has covered less than 80,000 miles when you purchase cover.",
      },
      {
        itemList: "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList:
          "The vehicle is registered and kept principally in the UK.",
      },

    ],
    // btnPurchase: "PURCHASE COVER",
    // label: "http://localhost:8000/gapInsurance#0",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover, you will receive a confirmation email enclosing your policy documents. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
    ],
  },
  {
    origin: "Return to Invoice",
    title: "Am I Eligible For Alloy Insurance?",
    description: `We aim to make alloy wheel insurance available to as many people as possible but there are some circumstances we can’t cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please don’t hesitate to contact us.`,
    benefitsList: "VEHICLE PURCHASED WITHIN THE LAST 90 DAYS:",
    criteria: "VEHICLE PURCHASED OVER 90 DAYS AGO:",

    benefitsListItems: [
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover",
      },
      {
        itemList:
          "The vehicles alloy wheels are free from damage when you purchase cover",
      },
      {
        itemList:
          "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK",
      },
    ],
    btnPurchase: "PURCHASE COVER",
    label: "http://localhost:8000/gapInsurance#0",
    criteriaListItems: [
      {
        itemList:
          "When you purchase cover you will receive a confirmation email enclosing your policy documents. This email will also provide instructions for having your alloy wheels inspected. You must follow these instructions within 30 days of purchasing cover or the policy will be declared null & void.",
      },
      {
        itemList:
          "The vehicle is less than 7 years old when you purchase cover",
      },
      {
        itemList:
          "The vehicle has covered less than 80,000 miles when you purchase cover",
      },
      {
        itemList:
          "The vehicles alloy wheels are free from damage when you purchase cover",
      },
      {
        itemList:
          "The alloy wheels are manufacturer fitted and supplied, not aftermarket such as Wolfrace, OZ Racing etc",
      },
      {
        itemList:
          "The vehicle will not be used for hire, reward, competition and is not owned/leased by a person or business involved in carrying out repairs, sales or servicing of vehicles. For example, Taxi, Courier, mechanic, car salesman.",
      },
      {
        itemList: "The vehicle is registered and kept principally in the UK",
      },
    ],
  },
];

export const tyreCover = [
  {
    coveredList: [
      {
        title: "Tyre Replacement",
        description:
          "We will pay for the replacement costs (subject to claim limit), for supplying and fitting the same make of tyre (subject to availability) following accidental or malicious damage. The amount we pay will be dependent upon the claim limit and period selected. A maximum of 2 claims per annum is allowed.",
      },
      {
        title: "Tyre Repair",
        description:
          "Pays the cost of punctures repairs to the tyre up to £50 (including VAT). Any benefit paid for “Repairs” will be included in the aggregate claims limit.",
      },
      {
        title: "No Excess",
        description: "There is no policy excess on our tyre insurance product.",
      },
      // {
      //   title: "National Repair Network",
      //   description: "Access to an established national Repairer network.",
      // },
      {
        title: "Policy To Cover Run Flat Tyres",
        description:
          "“Run-Flat Tyres” will be repaired wherever possible. In the event that a “Run-Flat Tyre” cannot be repaired, or the manufacturer states they should not be repaired, we will pay for an equivalent replacement tyre, subject to availability.",
      },
      {
        title: "We Pay The Going Rate",
        description:
          "Any costs for a replacement tyre will be based on the Dealer supply price or the Kwik-Fit supply price (or an alternative National supplier as appointed by the Administrator), or the claim limit, whichever is the lower.",
      },
    ],
    notCoveredList: [
      {
        title: "Wear & Tear",
        description: "We will not cover tyres that fail due to wear & tear.",
      },
      {
        title: "Certain Vehicles",
        description:
          "Vehicles which are used as an emergency vehicle, hire & reward, dispatch, courier, road racing, rallying, pace-making, speed testing or any other competitive event.",
      },
      {
        title: "Motor Trade",
        description:
          "Vehicles which are beneficially owned or operated by a company or persons involved in the business of vehicle repair, servicing, selling, trading or an employee of such a company or person.",
      },
      {
        title: "Time Of Incident",
        description:
          "Any incident or damage leading to a claim which occurred before the start of this policy.",
      },
      {
        title: "Previous Damage",
        description: "Repairs to previously damaged Tyres.",
      },
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },
      {
        title: "Age & Weight",
        description:
          "Any vehicle that is over 10 years old at policy inception and has a Gross Vehicle Weight of over 3.5 tonnes.",
      },
      {
        title: "Mileage",
        description: "Any vehicle over 100,000 miles at policy inception.",
      },
    ],
  },
];

export const Insurances = [
  {
    origin: "Finance Gap",
    title: "GAP insurance types",
    insuranceList: [
      {
        title: "Combination Return to Invoice",
        productGroupTitle: "Combined GAP and RTI Hire & Reward",
        description: "For vehicles bought from a dealer / internet car broker within the last 180 days, this policy pays the difference between the original invoice price you paid for your vehicle and your motor insurer’s settlement* following a total loss, or the amount required to settle any outstanding finance, whichever is the greater.",
      },
      {
        title: "Agreed Value / Shortfall Insurance",
        productGroupTitle: "Combination Shortfall Insurance",
        description: "For cars that were not bought from a dealer / internet car broker within the last 180 days or vehicles that were purchased from a dealer / internet car broker more than 180 days ago, this policy pays the difference between the Glass`s Guide Retail price for your vehicle, on the date you purchase this policy, and your motor insurer’s settlement* following a total loss, or the amount required to settle any outstanding finance, whichever is the greater.",
      },
      {
        title: "Contract Hire / Leasing GAP insurance",
        productGroupTitle: "Contract Hire and Leasing Gap",
        description: "If you bought your Vehicle on a Contract Hire or Leasing agreement within the last 180 days, this policy pays out the difference between the amount required to settle any outstanding finance and the amount of your motor insurer’s settlement* following a total loss. If your finance settlement figure is less than the amount you receive from your motor insurer, there will be no benefit due.",
      },
      {
        title: "Vehicle Replacement Insurance",
        productGroupTitle: "SMH Arch VRI",
        description: "For cars bought from a dealer / internet car broker within the last 180 days, this pays the difference between the replacement price for the equivalent make / model of your vehicle and your motor insurer’s settlement* following a total loss, or the amount required to settle any outstanding finance, whichever is the greater. If an equivalent make / model is no longer available, the claim will be settled based on the original invoice price you paid less your motor insurer’s settlement*",
      },
    ]
  }
]

export const Cover = [
  {
    origin: "Finance Gap",
    title: "Flexible GAP cover",
    secondTitle: "What's not covered",
    description: "We want to keep things easy for you. That’s why most brands and electric cars can be covered, and you can easily transfer your " + MyTheme.pageTitle + " GAP insurance policy if you change your vehicle.",
    coveredList: [
      {
        title: "Flexible Cover",
        description:
          "All of our GAP products allow for pro- rata refunds for the remainder of any unused term, should you sell the vehicle or decide you do not need cover, or you can transfer any remaining term to a replacement vehicle should you decide to change vehicles, but keep the cover in place.",
      },
      {
        title: "Vehicle Make & Model",
        description:
          "Covers all brands except Bugatti, Hummer, Honda NSX models, Lamborghini, Lotus, Maserati and Maybach, See the Terms and conditions for all vehicle eligibility criteria and exclusions.",
      },
      {
        title: "Electric Vehicles",
        description: "Electric vehicles are covered.",
      },
      {
        title: "Transferable Policy",
        description:
          "Transfer the policy to another vehicle that meets the eligibility criteria.",
      },
    ],
    notCoveredList: [
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },
      {
        title: "Age & Weight",
        description: "Any vehicle that is over 10 years old at policy inception and has a Gross Vehicle Weight of over 3.5 tonnes.",
      },
      {
        title: "Mileage",
        description:
          "Any vehicle over 100,000 miles at policy inception.",
      },
      // {
      //   title: "Supplying Dealer",
      //   description:
      //     "Any vehicle not purchased from an approved source within the last 90 days.",
      // },
    ],
  },
  {
    origin: "Tyre",
    title: "Going the extra mile for you",
    secondTitle: "What's not covered",
    description: "Buy tyre insurance from " + MyTheme.pageTitle + " and watch us go the extra mile for you, providing run-flat tyre insurance to replace your tyres. Unlike other insurance, we also allow you to make a second claim on the same tyre, should any problems occur.",
    coveredList: [
      {
        title: "Tyre Replacement",
        description:
          "We will pay for the replacement costs (subject to claim limit), for supplying and fitting the same make of tyre (subject to availability) following accidental or malicious damage. The amount we pay will be dependent upon the claim limit and period selected. A maximum of 2 claims per annum is allowed.",
      },
      {
        title: "Tyre Repair",
        description:
          "Pays the cost of punctures repairs to the tyre up to £50 (including VAT). Any benefit paid for “Repairs” will be included in the aggregate claims limit.",
      },
      {
        title: "No Excess",
        description: "There is no policy excess on our tyre insurance product.",
      },
      // {
      //   title: "National Repair Network",
      //   description: "Access to an established national Repairer network.",
      // },
      {
        title: "Policy To Cover Run Flat Tyres",
        description:
          "“Run-Flat Tyres” will be repaired wherever possible. In the event that a “Run-Flat Tyre” cannot be repaired, or the manufacturer states they should not be repaired, we will pay for an equivalent replacement tyre, subject to availability.",
      },
      {
        title: "We Pay The Going Rate",
        description:
          "Any costs for a replacement tyre will be based on the Dealer supply price or the Kwik-Fit supply price (or an alternative National supplier as appointed by the Administrator), or the claim limit, whichever is the lower.",
      },
    ],
    notCoveredList: [
      {
        title: "Wear & Tear",
        description: "We will not cover tyres that fail due to wear & tear.",
      },
      {
        title: "Certain Vehicles",
        description:
          "Vehicles which are used as an emergency vehicle, hire & reward, dispatch, courier, road racing, rallying, pace-making, speed testing or any other competitive event.",
      },


      {
        title: "Previous Damage",
        description: "Repairs to previously damaged Tyres.",
      },
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },


    ],
  },

  {
    origin: "MBI",
    title: "Give your cover a Boost",
    description: "Say no to unwanted repair bills. Shout YES to " + MyTheme.pageTitle + "’s instant warranty cover, which keeps you fully protected when your vehicle lets you down. For anything beyond wear and tear, we offer a premium package which includes your car’s battery, sat-nav, in-car entertainment and more!",
    coveredList: [
      {
        title: "Unexpected Costs",
        description:
          "A used vehicle is more likely to suffer a mechanical failure than a brand-new vehicle and we do not buy any age of vehicle on the presumption it is going to break down.",
      },
      {
        title: "Cheaper than the Cost of a Repair",
        description:
          "We may well have some type of insurance that we elect to pay for, and we may pay for that annually or on a monthly basis and yet we may never have to use it.",
      },
      {
        title: "Expenses other than an Actual Repair",
        description:
          "Your vehicle may not be able to be repaired on the same day. Depending on the failure it may well be out of action for a few days or even weeks.",
      },
      {
        title: "Wear & Tear",
        description:
          "Wear and Tear cover is available as an upgrade and (if selected and the additional premium is paid), means that if a part breaks, or fails, due to the mileage or running of the vehicle, it is typically considered as failure due to “Wear & Tear”…",
      },
      {
        title: "Boost",
        description:
          "Boost cover is available as an upgrade on all our warranties and (if selected and the additional premium is paid), We would cover the Battery, Catalytic Convertor, Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
      },
      // {
      //   title: "Expenses other than an Actual Repair",
      //   description:
      //     "Your vehicle may not be able to be repaired on the same day. Depending on the failure it may well be out of action for a few days or even weeks.",
      // },
    ]
  },
  {
    origin: "Cosmetic Repair",
    title: "High claims limit, low excess",
    secondTitle: "What's not covered",
    description: "Simply register online today and put an end to your stone dent nightmares. " + MyTheme.pageTitle + "’s Cosmetic Insurance covers your vehicle for both everyday and business use (if purchased), providing repair costs of up to £250 per claim, with an excess charge of just £10.",
    coveredList: [
      {
        title: "Damage Caused To Your Vehicle",
        description:
          "With " + MyTheme.pageTitle + "’s Cosmetic Repair Insurance, the vehicle is covered for damage caused by everyday motoring or business use (if Business Use product purchased).",
      },
      {
        title: "High Claims Limit",
        description:
          "Customers can claim up to £250 per individual repair and £3,000 in aggregate over the period of insurance. Any repairs that exceed £250 are not considered to be minor damage.",
      },
      {
        title: "Low Excess",
        description:
          "There is a £10 excess payable on each individual repair made.",
      },
      {
        title: "Minor Dent",
        description:
          "Minor Dent means damage to a metal body panel, not exceeding 30cm in diameter and where such panel has not been ripped, perforated or torn.",
      },
      {
        title: "Chips",
        description:
          "Chip means damage to the painted vehicle not exceeding 1.5mm in diameter",
      },
      {
        title: "Light Scratches",
        description:
          "Light Scratch means damage to the vehicle body panel not exceeding 30cm in length and not extended over more than 2 (two) adjacent body panels.",
      },
      {
        title: "Scuffs",
        description:
          "Scuff(s) means light scraping of the top surface paint, not penetrating to the base material of the Vehicle and not exceeding 30cm in diameter.",
      },
      {
        title: "Contribution",
        description:
          "If we are unable to repair the damage we will pay a contribution towards repair.",
      },
    ],
    notCoveredList: [
      {
        title: "Market Value",
        description:
          "Any vehicle where the invoice price / current market value exceeds £100,000.",
      },
      {
        title: "Age & Weight",
        description:
          "Any vehicle that is over 10 years old at policy inception and has a Gross Vehicle Weight of over 3.5 tonnes.",
      },
      {
        title: "Mileage",
        description: "Any vehicle over 100,000 miles at policy inception.",
      },
      {
        title: "Supplying Dealer",
        description:
          "Any vehicle not purchased from an approved source within the last 90 days.",
      },
    ],
  },
  {
    origin: "Alloy Wheel",
    title: "Start covering the cost of accidental damage repair today",
    secondTitle: "What's not covered",
    description: "Get the alloy wheel insurance that's right for you by choosing from our two main levels of cover, each designed to best suit your wheel size and alloy design. Choose between 12 month, 24 month or 36 month alloy wheel insurance.",
    coveredList: [
      {
        title: "Replacement Alloy Wheel",
        description:
          "If the alloy wheel can’t be repaired, we will contribute towards a replacement.",
      },
      {
        title: "Instant Protection",
        description:
          "Instant Cover for vehicles purchased within last 90 days.",
      },
      {
        title: "No Excess",
        description:
          "There is No Excess on this policy if you should need to claim.",
      },
      {
        title: "Diamond Cut & Polished",
        description:
          "Cover for Diamond Cut & Polished with our Gold level of cover.",
      },
      {
        title: "Transferable",
        description:
          "Transferable to a replacement vehicle (subject to £15 admin fee).",
      },
      {
        title: "Purchase Cover Anytime",
        description:
          "Cover for vehicles purchased outside of the last 90 days – subject to inspection by us or our approved repairer.",
      },
    ],
    notCoveredList: [
      {
        title: "Certain Vehicles",
        description:
          "Vehicles which are used as an emergency vehicle, hire & reward, dispatch, courier, road racing, rallying, pace-making, speed testing or any other competitive event.",
      },
      {
        title: "Time Of Incident",
        description:
          "Any incident or damage leading to a claim which occurred before the start of this policy.",
      },
      {
        title: "Previous Damage",
        description: "Repairs to previously damaged Alloy Wheels.",
      },
      {
        title: "Alloy Wheel Theft",
        description: "Theft of Alloy Wheels.",
      },
    ],
  },
  {
    origin: "Return to Invoice",
    coveredList: [
      {
        title: "Flexible Cover",
        description:
          "All of our GAP products allow for pro- rata refunds for the remainder of any unused term, should you sell the vehicle or decide you do not need cover, or you can transfer any remaining term to a replacement vehicle should you decide to change vehicles, but keep the cover in place.",
      },
      {
        title: "Vehicle Make & Model",
        description:
          "Covers all brands except Bugatti, Hummer, Honda NSX models, Lamborghini, Lotus, Maserati and Maybach, See the Terms and conditions for all vehicle eligibility criteria and exclusions.",
      },
      {
        title: "Electric Vehicles",
        description: "Electric vehciles are covered.",
      },
      {
        title: "Transferable Policy",
        description:
          "Transfer the policy to another vehicle that meets the eligibility criteria.",
      },
    ],
    notCoveredList: [
      {
        title: "Invoice Price / Current Market Value",
        description:
          "The invoice price / current market value of insured vehicle must not exceed £125,000. (£75,000 on GAP).",
      },
      {
        title: "Vehicle Age",
        description: "Any vehicle over 10 years old at inception of the policy",
      },
      {
        title: "Vehicle Weight",
        description:
          "Any vehicle weighing over 3.5 tonnes (7 tonnes on Motorhomes).",
      },
      {
        title: "Maximum Mileage",
        description:
          "Any vehicle with a mileage over 100,000 miles at policy inception.",
      },
      {
        title: "Vehicle Purchase Conditions",
        description:
          "Any vehicle not purchased from an approved source within the last 180 days (excluding Shortfall).",
      },
    ],
  },
  {
    origin: "Wedding",
    title: "What does wedding insurance cover?",
    description: "Ensure your big day is fully covered with " + MyTheme.pageTitle + "’s insurance packages including everything from cancellations to wedding attire, rings to cakes, flowers to transportation, and much more! Take a look for yourself at the extensive list of items and services that " + MyTheme.pageTitle + "’s great-value Wedding Insurance has to offer, depending on the level cover selected:",
    coveredList: [
      {
        title: "Cancellation Cover",
        description:
          "With " + MyTheme.pageTitle + "’s Cosmetic Repair Insurance, the vehicle is covered for damage caused by everyday motoring or business use (if Business Use product purchased).",
      },
      {
        title: "Re-arrangement Cover",
        description:
          "In the event of the cancellation of your planned wedding, it may be that you wish to rearrange an alternative ceremony.",
      },
      {
        title: "Ceremonial Attire",
        description:
          "All " + MyTheme.pageTitle + " Wedding Insurance policies offer cover to protect you against the accidental complete loss of or severe damage to the ceremonial attire which renders the items unwearable and where the purchase or hire of alternatives is not possible. Whether that’s the caterers, the photographer, or any other wedding supplier, in this current economic environment nothing is certain. This can obviously have huge implications for your wedding especially if you are unable to recoup any deposits you may have paid. If this does occur then, in line with the policy wording, you will be covered for any irrecoverable deposits and any additional costs you face in arranging an alternative, equivalent service between £3,000* and £15,000*. Cover for Financial Failure is included as standard on all Wedding Insurance policies because we want you to have the wedding you have always dreamed of and aren’t left out of pocket because of something out of your control.",
      },
      {
        title: "Wedding Gifts",
        description:
          "With our Wedding Gifts Coverage, included in all of our policies, your gifts are covered for up to 7 days before your big day and for 24 hours afterwards.",
      },
      {
        title: "Rings, Flowers, Gifts and Cake",
        description:
          "You may be surprised to hear that the average couple spends around £300 on the cake for their big day which in itself is a big purchase, even if it’s a relatively small proportion of your wedding spend.",
      },
      {
        title: "Wedding Cars & Transport",
        description:
          "All " + MyTheme.pageTitle + " Wedding Insurance policies offer financial protection for unforeseen additional costs to arrange alternative transport.",
      },
      {
        title: "Photography & Video",
        description:
          "There is a long list of things that can go wrong when it comes to wedding photos and wedding videos.",
      },
      {
        title: "Financial Failure",
        description:
          "It’s fair to say most couples worry about some of their wedding service suppliers going under before their big day.",
      },
      {
        title: "Personal Accident",
        description:
          "All " + MyTheme.pageTitle + " Wedding Insurance policies offer cover for the marrying couple against personal accident, which includes the loss of sight, a limb or any permanent disablement within 12 months of a covered accident.",
      },
      {
        title: "Professional Counselling",
        description:
          "All " + MyTheme.pageTitle + " Wedding Insurance policies offer cover in respect of professional counselling that is recommended by a general practitioner for either of the marrying couple.",
      },
      {
        title: "Legal Expenses",
        description:
          "All " + MyTheme.pageTitle + " Wedding Insurance policies offer cover for any legal costs and expenses incurred by you, in the pursuit of legal proceedings by you or your personal representative(s).",
      },
      {
        title: "Public Liability",
        description:
          "All the " + MyTheme.pageTitle + " Wedding Insurance policies include £2 million of Public Liability cover for the Marrying Couple as standard.",
      },
      {
        title: "Marquee & Event Equipment",
        description:
          "If you are holding your Wedding ceremony or reception in a standalone marquee, then it makes sense to cover this within your Wedding Insurance policy.",
      },
      {
        title: "Ceremonial Swords",
        description:
          "Ceremonial Swords can play a big part in couples’ weddings or civil partnerships, typically those with in the armed forces but also in some religious ceremonies.",
      },
      {
        title: "Coronavirus Cover",
        description:
          "After everything that has happened across the world over the last 2 years, you might decide to take the upgrade to add coronavirus cover.",
      },
    ]
  },
  {
    origin: "Excess",
    title: "What sets our excess insurance apart from the competition?",
    description: "",
    coveredList: [
      {
        title: "What We Cover",
        description: "We will cover the lower of either the claim limit selected or the excess due on their motor insurance policy, subject to: The vehicle repairs in connection with the claim under their Motor Insurance Policy being completed or; In the event of the Insured Vehicle being declared a Total Loss by their Motor Insurer, the replacement vehicle purchased by them from our Approved Dealer Network.",
      },
    ],
  },
];
export const benefitsTyre = [
  {
    title: "Benefits Of Using " + MyTheme.pageTitle + ".com",
    description:
      "We aim to make GAP insurance available to as many people as possible but there are some circumstances and vehicles that we cannot cover. We ask that you read this page carefully to make sure our policy is right for you. If you have any questions, please do not hesitate to contact us.",
    benefitsList: "SIGNIFICANT BENEFITS OVER OUR COMPETITORS PRODUCTS",
    criteria: "ELIGIBILITY CRITERIA",
    benefitsListItems: [
      {
        itemList: "Highly competitive pricing.",
      },
      {
        itemList:
          "Wider vehicle eligibility criteria and more flexible cover than competitors.",
      },
      {
        itemList:
          "Our GAP insurance products can be purchased up to 180 days after you take delivery of the vehicle. (Shortfall Insurance is available if more than 180 days)",
      },
      {
        itemList:
          "Direct and simple claims process and we handle all claims in-house, in the UK.",
      },
      {
        itemList:
          "Transferable to a replacement vehicle that meets the eligibility criteria – subject to an administration fee",
      },
      {
        itemList:
          "Cover available for most vehicle makes / models, including electric vehicles",
      },
    ],
    btnPurchase: "PURCHASE COVER",
    label: "/checkout",
    criteriaListItems: [
      {
        itemList:
          "The invoice price / current market value of insured vehicle does not exceed £125,000. (£75,000 on GAP).",
      },
      {
        itemList:
          "The insured vehicle is less than 10 years old at inception of the policy and has a maximum GVW of 3.5 tonnes (7 tonnes on Motorhomes).",
      },
      {
        itemList:
          "The insured vehicle has covered less than 100,000 miles at inception of the policy",
      },
      {
        itemList:
          "The insured vehicle was purchased from an approved source within the last 180 days. If the vehicle was purchased separately or more than 180 days ago, you can still purchase our Shortfall Insurance.",
      },
    ],
  },
];
export const gapInsurance = [
  {
    title: "gap insurance",
    description:
      MyTheme.pageTitle + ".com offers some of the most comprehensive GAP products on the market with competitive rates to match.",
    link: "https://" + MyTheme.pageUrl + "/gap-insurance/#whats-covered",
    label1: "What is covered?",
    label2: "Purchase cover",
  },
];

export const howWork = [
  {
    title: "How Does GAP Insurance Work?",
    description:
      "We will provide you with protection against financial loss they may incur as a result of their vehicle being a total write-off due to accident, fire, vandalism, flood or theft.",
    conclusion:
      "GAP Insurance is a supplementary insurance that protects you financially when your vehicle is written off. Please remember that if your vehicle is written off, your own motor insurance company is only ever legally obliged to offer you the value of your vehicle on the day it was written off. With the average vehicle predicted to depreciate by up to 50% within the first three years alone, this means that the amount that you are offered in settlement can be, and often is, thousands of pounds less than the original amount that you paid.",
  },
];
export const returnInvoice = [
  {
    title: "Combination Return To Invoice",
    listItemDescription1: "The original purchase price of the Vehicle or",
    listItemDescription2:
      "The outstanding settlement value on the finance agreement, subject to the original amount financed being less than or equal to the Vehicle purchase price.",
    description:
      "If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the financial shortfall between the Insured Value and the greater of: ",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const invoiceHire = [
  {
    title: "Combination Return to Invoice – Hire & Reward",
    description:
      "Specifically designed for Taxis, Driving Schools, Couriers and Chauffeurs, If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the financial shortfall between the Insured Value and the greater of: ",
    listItemDescription1: "The original purchase price of the Vehicle or",
    listItemDescription2:
      "The outstanding settlement value on the finance agreement, subject to the original amount financed being less than or equal to the Vehicle purchase price.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const leasing = [
  {
    title: "Contract Hire & Leasing GAP",
    description:
      "If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the difference between the Insured Value of Your Vehicle and the Outstanding Balance under Your Contract Hire or Leasing Agreement.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const leasingHire = [
  {
    title: "Contract Hire & Leasing GAP – Hire & Reward",
    description:
      "Specifically designed for Taxis, Driving Schools, Couriers and Chauffeurs, If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the difference between the Insured Value of Your Vehicle and the Outstanding Balance under Your Contract Hire or Leasing Agreement.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];
export const shortfall = [
  {
    title: "Shortfall Cover",
    description:
      "If within the Period of Insurance an incident occurs within the stated Geographical Limits which results in a Total Loss/Write Off under a Motor Insurance Policy, this insurance will pay the financial shortfall between the Insured Value and the greater of:  ",
    listItemDescription1:
      "The current “Retail” market value of the Vehicle as shown in Glass`s Guide, on the date you purchase this insurance or",
    listItemDescription2:
      "The outstanding settlement value on the finance agreement, subject to the original amount financed being less than or equal to the Vehicle purchase price.",
    conclusion:
      "This is subject to a maximum benefit not exceeding the Sum Insured shown on the Proposal / Policy Schedule and will include Motor Insurance Policy excess up to an amount of £250. Only one claim can be made under this Policy during the Period of Insurance.",
  },
];

export const gapInsuranceCards = [
  {
    title: "24 months",
    price: "£85",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 months",
    price: "£115",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£160",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
];
export const gapInsuranceCards2 = [
  {
    title: "24 months",
    price: "£199",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined GAP & RTI Hire & Reward",
    sublineType: "",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 months",
    price: "£239",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined GAP & RTI Hire & Reward",
    sublineType: "",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£339",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined GAP & RTI Hire & Reward",
    sublineType: "",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const gapInsuranceCards3 = [
  {
    title: "24 months",
    price: "£85",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 Months",
    price: "£103",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£160",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "60 Months",
    price: "£112",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP",
    months: "60 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const gapInsuranceCards4 = [
  {
    title: "24 months",
    price: "£210",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 Months",
    price: "£230",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£250",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "60 Months",
    price: "£270",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Combined Contract Hire &",
    sublineType: "Leasing GAP – Hire & Reward",
    months: "60 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const gapInsuranceCards5 = [
  {
    title: "24 months",
    price: "£85",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Shortfall Cover",
    sublineType: "",
    months: "24 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "36 Months",
    price: "£115",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Shortfall Cover",
    sublineType: "",
    months: "36 Months Cover",
    btnPurchase: "purchase cover",
  },
  {
    title: "48 Months",
    price: "£160",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "Shortfall Cover",
    sublineType: "",
    months: "48 Months Cover",
    btnPurchase: "purchase cover",
  },
];

export const aboutUs = [
  {
    title: "WHAT OUR CUSTOMERS SAY ABOUT US",
    testimonials:
      ' " I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend ' + MyTheme.pageUrl,
    author: "- Mr Bronwick",
  },
];

// ----------------------------------Tyre Insurance---------------

export const tyreInsuranceHeader = [
  {
    title: "Tyre Insurance",
    description:
      "We will protect the customer in the event of sustaining either accidental or malicious damage to tyres fitted to the Insured Vehicle.",
    link: "#0",
    label1: "Purchase cover",
    label2: "What's covered?",
  },
];

export const tyreInsuranceParagraph = [
  {
    title: "Tyre Insurance For Your Vehicle",
    description:
      "We will protect the customer in the event of sustaining either accidental or malicious damage to tyres fitted to the Insured Vehicle.",
    conclusion:
      "The cover includes the cost of replacement or repair of a tyre, up to the claim limit purchased, as long as the tyre tread depth is above the UK “Legal Roadworthy limit” at the time of the claim.",
  },
];

export const tyreStandardType = [
  {
    title: "Standard Tyre insurance prices",
    description:
      "Our standard tyre insurance, provides protection against sustaining either accidental or malicious damage to tyres under 18″ in size. Excluding wear & tear.   * prices may vary due to your vehicle eligibility",
  },
];
export const variousTypesOfGap = [
  {
    title: "What are the various types of GAP available via " + MyTheme.pageTitle + "*",
    description: "* prices may vary due to your vehicle eligibility",
  },
];
export const tyreOverType = [
  {
    title: "18″ & Over Tyre Insurance Prices*",
    description:
      "Our 18″ & over tyre insurance, provides protection against sustaining either accidental or malicious damage to tyres 18″ or over. Excluding wear & tear.   * prices may vary due to your vehicle eligibility",
  },
];

export const standardTypeCard = [
  {
    title: "£125 Limit",
    price: "£99",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "24 Months Cover",
    tyresUnder: "Tyres Under 18",
    btnPurchase: "purchase cover",
  },
  {
    title: "£125 Limit",
    price: "£159",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "36 Months Cover",
    tyresUnder: "Tyres Under 18",
    btnPurchase: "purchase cover",
  },
];

export const overTypeCard = [
  {
    title: "£250 Limit",
    price: "£249",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "24 Months Cover",
    tyresUnder: "Covers 18″ & Over",
    btnPurchase: "purchase cover",
  },
  {
    title: "£250 Limit",
    price: "£379",
    descriptionPrice1: " inc. IPT at 12%",
    descriptionPrice2: "T&Cs",
    descriptionPrice3: "IPID",
    type: "No Excess",
    sublineType: "Up To 2 Claims Per Year",
    months: "36 Months Cover",
    tyresUnder: "Covers 18″ & Over",
    btnPurchase: "purchase cover",
  },
];

/* Homepage header imports and exports */

export const homepageTitle = "Save Time, Save The Hassle, " + MyTheme.pageTitle + ".com";
export const homepageDescription = `GAP insurance, alloys, cosmetic, tyre, mechanical breakdown warranties..
these are the fiddly, twiddly types of insurance you don't usually think about. But without them, you might develop an irrational fear of car parks and kerbs. And how would you do your shopping then?
`;

export const plantImg = plant;
export const fcaImg = fca;
export const bibaImg = biba;

/*Homepage cards */

export const homepageCards = [
  {
    origin: "Return to Invoice",
    title: "Return to invoice",
    description:
      " Combined shortfall insurance is designed to pay the shortfall between the insurer’s market value and the original purchase price of your vehicle..",
    image: gap26,
    findOutMoreButton: "Find out more",
    linkList: "/gap-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "Finance Gap",
    title: "Gap Insurance",
    description: `Having your vehicle accidentally damaged, set on fire or stolen is bad enough, right?
Now imagine being hit with a huge bill on top of that? 
Instead, cover-up with our affordable combined GAP and RTI insurance, designed to 
pay the shortfall between the insurer’s market value and the original cost of your 
vehicle.
`,
    image: gap26,
    findOutMoreButton: "Find out more",
    linkList: "/gap-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "Alloy Wheel",
    title: "Alloy Insurance",
    description: `Scraped the curb? It’s okay. We’ve got you. 
    With this Alloy Wheel product, you’re covered for 
    up to £200 per claim, and up to £2,000 in Aggregate.`,
    image: alloy26,
    findOutMoreButton: "Find out more",
    linkList: "/alloy-insurance",
    getQuoteButton: "Get a quote",
  },

  {
    origin: "Tyre",
    title: "Tyre Insurance",
    description: `Don’t let a puncture stop you in your tracks.
Whether you’ve driven over a nail, or made a new enemy (don’t worry, we’re not one 
to pry!), our policy covers you for both accidental and malicious damage.`,
    image: tyre26,
    findOutMoreButton: "Find out more",
    linkList: "/tyre-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "MBI",
    title: "Vehicle Warranty",
    description: `We’ve all been there, and there’s no way of sugar-coating it.
Your vehicle breaking down is stress. Pure stress.
But if it ever happens to you, and you’re sat on the hard shoulder in a huff, be 
thankful you signed up for our 12 months Elite Vehicle Warranty, and 
stress no more.`,
    image: mbi26,
    findOutMoreButton: "Find out more",
    linkList: "/vehicle-warranty-insurance",
    getQuoteButton: "Get a quote",
  },
  {
    origin: "Cosmetic Repair",
    title: "Cosmetic Repair Insurance",
    description: `Our cosmetic repair policy is as cheap as, well… chips! 
Seriously, never worry again about dents, scratches, scuffs or even stone chips. 
We’ll have you covered for any claim up to £250, with a generous aggregate claim 
limit of £3,000. 
Is your vehicle for business use? No worries, we’ve got you covered, too.`,
    image: cosmetic26,
    findOutMoreButton: "Find out more",
    linkList: "/cosmetic-repair-insurance",
    getQuoteButton: "Get a quote",
  },

  {
    origin: "Excess",
    title: "Excess Insurance",
    description: `We know it’s not your fault if somebody damagers your vehicle.
We also know you’re not to blame if your vehicle breaks down unexpectedly.
That’s why we want to minimise your expense in the event that you have to make a claim.
`+ MyTheme.pageTitle + `’s excess insurance allows us to take the hit when it comes to your excess fee, leaving you stress-free.`,
    image: mbi26,
    findOutMoreButton: "Find out more",
    linkList: "/excess-insurance",
    getQuoteButton: "Get a quote",
  },
];

/*Homepage cards */

export const couchTitle = "You're buying peace of mind";
export const couchContent =
  "We’re 110% kosher, regulated by the Financial Conduct Authority, a member of BIBA, and we’ve got our cycling proficiency badges. We also currently provide products to over £180m-worth of UK vehicles. So you can be sure there’s no funny monkey business with us.";
export const couchImg = relaxing;

/*Card Title */
export const formTitle = `We've got your insurance covered`;

/*Rating image */

export const ratingImg = rating;

/*Secondary pages header */

export const secondaryPagesHeader = [
  {
    origin: "MBI",
    title: "Vehicle Warranty",
    description:
      "We can protect you from the unexpected expense of bills for mechanical repairs, with our Mechanical Breakdown Insurance.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: mbi,
    imgwebp: imgWarranty,
    imgpng: imgWarrantypng,

  },
  {
    origin: "Alloy Wheel",
    title: "Alloy Wheel Insurance",
    description:
      "Cover the cost of alloy wheel repairs, should they become damaged.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: alloyHeader,
    imgwebp: imgAlloy,
    imgpng: imgAlloypng,
  },
  {
    origin: "Finance Gap",
    title: "GAP Insurance",
    description:
      MyTheme.pageTitle + ".com offers some of the most comprehensive GAP products on the market with competitive rates to match.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: gapHeader,
    link: "#",
    label: "What is covered?",
    imgwebp: imgGAP,
    imgpng: imgGAPpng,
  },
  {
    origin: "Tyre",
    title: "Tyre Insurance",
    description:
      "We will protect the customer in the event of sustaining either accidental or malicious damage to tyres fitted to the Insured Vehicle.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: tyreHeader,
    imgwebp: imgTyre,
    imgpng: imgTyrepng,
  },
  {
    origin: "Cosmetic Repair",
    title: "Cosmetic Insurance",
    description:
      "Our cosmetic insurance, sometimes called Cosmetic Repair, Scratch & Dent or SMART Dent, provides protection for minor dents, stone chips, scratches and scuffs.",
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: cosmetic,
    imgwebp: imgCosmetic,
    imgpng: imgCosmeticpng,
  },
  {
    origin: "Excess",
    title: "Excess Insurance",
    description: MyTheme.pageTitle + `’s excess insurance allows us to take the hit when it comes to your excess fee, leaving you stress-free.`,
    checks: [
      {
        check: "Providing products to over £180 million worth of vehicles across the UK",
      },
      {
        check: "A tree planted with every purchase",
      },
      {
        check: "Decades of experience & FSCS protected",
      },
    ],
    image: mbi26,
    imgwebp: imgExcess,
    imgpng: imgExcesspng,
  },
  {
    origin: "Return to Invoice",
    title: "RETURN TO INVOICE",
    description:
      "Combined GAP and RTI insurance is designed to pay the shortfall between the insurer’s market value and the original purchase price of your vehicle, following the total loss of your vehicle due to accidental damage, fire or theft. Claim limits from £5,000 to £50,000 over periods of 2-4 years available.",
    image: alloyHeader,
  },

];


// header paragraph

export const headerParagraph = {
  "About Us": [
    {
      title: `Competitive, quality vehicle protection products – all under one roof!`,
      description: `We started ` + MyTheme.pageTitle + ` for one core reason – I bet you’ll never guess what that 
  was? That’s right! To save our customers both time and money. 
  But not only that.\n
  The team here at `+ MyTheme.pageTitle + ` believe in keeping things simple, which is why we 
  cater for all of your vehicle’s needs, all in-house, all under one roof. 
  That way, you don’t have to keep shopping around each time any slight thing 
  happens with your vehicle.`,
      button: "abc",
    },
  ],
  "Tree Planted": [
    {
      title: "With every policy purchased from " + MyTheme.pageTitle + " a tree is planted",
      description: `Responsibility is an important word to us here at ` + MyTheme.pageTitle + `. Not only are we responsible for your car’s every need, 
      but we do our part in being responsible for nurturing our planet. That’s why for every policy sold, we plant one tree. 
      If you think about it, that’s A LOT of trees. Especially when you consider our policy prices.`,
      subtitle: "Want to help plant a tree today?",
      button: "abc",
      imageLeft: treeLeft,
    }
  ],
  "Alloy Wheel": [
    {
      title: "Alloy Insurance - Save up to £120 per alloy",
      description: `Scraped your alloy wheels? No problem.
      
Hit a kerb? Don’t worry.
    
Driven over a pothole? It’s all good. We’ve got you covered.`,
    },
    {
      title: "Pristine Alloy Wheels - Without the Cost of Repair",
      description: `Alloy wheels look brilliant.

But what’s not brilliant is being hit with a large bill to fix unwanted scuffs or scratches.

So don’t let it happen. Cover your tracks. Protect unwanted alloy wheel repair payment with Alloy Insurance from `+ MyTheme.pageTitle + `, saving up to £120 for each alloy that gets damaged.`,
    },
    {
      title: "Protect yourself and your vehicle against unforeseen costs",
      description: `At ` + MyTheme.pageTitle + `, we are here to offer you the ultimate peace of mind when it comes to your vehicle.`,
    },
    {
      title: "Start Covering the Cost of Accidental Damage Repair Today",
      description: `Get the alloy wheel insurance that’s right for you by choosing from our two main levels of cover, each designed to best suit your wheel size and alloy design.
 
Choose between 12 month, 24 month or 36 month alloy wheel insurance.
      
Want more details? Then please see below.`,
    },
  ],
  Tyre: [
    {
      title: "Tyre Insurance - Cover All Replacement Tyre Costs",
      description: `Protect against accidental damage to your vehicle’s tyres with this great-value tyre insurance from ` + MyTheme.pageTitle + `.`,
    },
    {
      title: "Protect Against Tyre Repair Costs",
      description: `Ease the pain of punctures and cover the cost of repair to your damaged tyres with ` + MyTheme.pageTitle + `’s insurance. `,
    },
    {
      title: "Going The Extra Mile for You",
      description: `Buy tyre insurance from ` + MyTheme.pageTitle + ` and watch us go the extra mile for you, providing run-flat tyre insurance to replace your tyres.

Unlike other insurance, we also allow you to make a second claim on the same tyre, should any problems occur. 

Want more details? Then please see below.`,
    },
  ],
  "Finance Gap": [
    {
      title: "GAP Insurance - Don’t Get Caught Short",
      description: `When we buy our vehicles, the last thing we ever imagine is the possibility it may be written off. 

That’s why many vehicle owners are left out of pocket when the unthinkable happens, as all insurers are only legally obliged to compensate the current market value of your vehicle.

GAP Insurance from `+ MyTheme.pageTitle + ` provides protection against financial loss that may incur as a result of your vehicle being a total write-off due to accident, fire, vandalism, flood or theft.`,
    },
    {
      title: "Protect Against Depreciation",
      description: `As you will know, the moment a vehicle is driven off the showroom, its value begins to drop like a stone. 

In fact, the average vehicle is predicted to depreciate by up to 50% within the first three years alone.

This means the amount that you are offered in settlement can be, and often is, thousands of pounds less than the original 
amount that you paid.

`+ MyTheme.pageTitle + ` provides a solution to this problem, offering comprehensive cover which significantly boosts your payment 
in the event of a write-off.`,
    },
    {
      title: "Flexible GAP cover ",
      description: `We want to keep things easy for you.

That’s why most brands and electric cars can be covered, and you can easily transfer your `+ MyTheme.pageTitle + ` GAP insurance policy if you change your vehicle.

Want more details? Then please see below.
`,
    },
  ],

  "Cosmetic Repair": [
    {
      title: "Cosmetic Insurance - Great-Value SMART Repair ",
      description: `Drive the car you love without the hassle of damage costs thanks to ` + MyTheme.pageTitle + `’s cosmetic repair insurance, providing cover for minor dents, stone chips, scratches and scuffs.`,
    },
    {
      title: "Great-Value SMART Repair Insurance ",
      description: `Insurance for Small Medium Area Repair Technology (or SMART Repair) can be very expensive, especially when purchased from your local car dealer. 

That’s where `+ MyTheme.pageTitle + ` comes in, like our name suggests, to help you cut costs and offer you vehicle repair by one of our authorised specialists, helping to keep your vehicle in showroom condition.
`,
    },
    {
      title: "High Claims Limit, Low Excess",
      description: `Simply register online today and put an end to your stone dent nightmares.

`+ MyTheme.pageTitle + `’s Cosmetic Insurance covers your vehicle for both everyday and business use (if purchased), providing repair costs of up to £250 per claim, with an excess charge of just £10. 

Want more details? Then please see below`,
    },
  ],

  MBI: [
    {
      title: "Vehicle Warranty – It's time to eliminate expensive bills",
      description: `With ` + MyTheme.pageTitle + `, you can rest easy, even if your vehicle lets you down.
     
Because we can cover up to 100% of your breakdown repair costs, so you don’t get caught with an unexpected bill.

Mechanical and electrical failures can happen, and do happen.
      
So get covered, and let `+ MyTheme.pageTitle + ` cushion any unforeseen blows with secure and reliable breakdown insurance.`,
    },
    {
      title: "Take Cover Against Mechanical and Electrical Car Failures",
      description: `Our vehicle warranty is there to keep you fully covered. 

 

That's why we take care of costs for both parts and labour in the event of any mechanical or electric faults. 

 

Remember, our vehicle warranty is much cheaper than the cost of repairs, so take advantage today.
     `,
    },
    {
      title: "Remove the stress, let us take care of your repair",
      description: `We can't predict the future, but ` + MyTheme.pageTitle + ` accounts for all possibilities, to give you the ultimate peace of mind in any given situation.

We know it's stressful when your vehicle lets you down, so we leave no stone unturned in making it as comfortable as possible for you in such an event.`,
    },

    {
      title: "Give Your Cover a Boost",
      description: `Say no to unwanted repair bills. 

 

Shout YES to `+ MyTheme.pageTitle + `'s instant warranty cover, which keeps you fully protected when your vehicle lets you down. 

 

For anything beyond wear and tear, we offer a premium package which includes your car's battery, sat-nav, in-car entertainment and more! 
`,
    },
  ],
  Excess: [
    {
      title: `Say No to Excess - Cover All Eventualities`,
      description: `Cover yourself in all situations with ` + MyTheme.pageTitle + `’s excess car insurance, designed to keep unnecessary bills away in the event of making a claim.

But what does excess insurance cover?

Put simply, whenever you have to make claim under your motor insurance policy, we will pay for any excess costs incurred, unless it exceeds your claims limit.

Want more details? Then please see below.`,
    },
  ],
  Wedding: [
    {
      title: ``,
      description: `Weddings and civil ceremonies are a special cause for celebration; a day you will remember for the rest of your life.We know they can cost a lot, both financially and emotionally. 

We also understand how stressful it can be if something doesn’t quite go how you planned it.

That’s why at `+ MyTheme.pageTitle + `, we don’t leave anything to chance, offering Wedding Insurance to give you the  peace of mind on your big day.`,
      quoteButton: "abc",
    },
    {
      title: `Do I Need Wedding Insurance?`,
      description: `Wedding insurance from ` + MyTheme.pageTitle + ` is there to offer financial protection should things go wrong before or on your wedding day. The last thing you want is something to go wrong on your big day, but if it does, don’t make it worse by being stressed and unnecessarily out of pocket. 

“What could possibly go wrong?” You may ask.

Well, without sending you into a cold sweat, here’s a few reasons why at `+ MyTheme.pageTitle + `, we expect the unexpected:

No-shows from vendors, venue closures, cancellations, sudden illness, extreme weather, damage, loss, theft, and of course, global pandemic outbreaks!

These covers are subject to the terms and conditions of the policy wording and an additional premium may need to be paid for some sections.

So don’t get cold feet when it comes to Wedding Insurance. Tie the knot with `+ MyTheme.pageTitle + ` to gift yourself peace of mind for your truly special occasion.`,
    },
    {
      title: `What Does Wedding Insurance Cover?`,
      description: `Protect your finances with Wedding Insurance from ` + MyTheme.pageTitle + `, featuring five ranges of cover, each with a choice indemnity limits, to let you choose the package that best suits you. 

Ensure your big day has financial protection with `+ MyTheme.pageTitle + `’s insurance packages including many things from cancellations to wedding attire, rings to cakes, flowers to transportation, and much more!`,
    },
  ],
};

export const ProgressBarSteps = [
  "Your details",
  "Vehicle details",

  "Available products",
  "The Legal Bit",
  "Complete",
];

export const reviews = [
  {
    review: `"I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend ` + MyTheme.pageUrl + `."`,
    name: "- Mr Bronwick",
  },
  // {
  //   review:`"I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend `+MyTheme.pageUrl+`."`,
  //   name:'- Mr Bronwick'
  // },
  // {
  //   review:`"I saved a lot of money on both my alloy and gap insurance compared to what my dealer quoted me, friendly, and fast service. I would definitely recommend `+MyTheme.pageUrl+`."`,
  //   name:'- Mr Bronwick'
  // },
];

export const mbiPageContent = [
  {
    origin: "MBI",
    coveredList: [
      {
        title: "Unexpected Costs:",
        description:
          "A used vehicle is more likely to suffer a mechanical failure than a brand-new vehicle and we do not buy any age of vehicle on the presumption it is going to break down. Any Mechanical faults, and their related costs, will come as an unexpected blow at any time, but a " + MyTheme.pageTitle + " warranty could help to cushion that blow.",
      },
      {
        title: "Cheaper than the Cost of a Repair:",
        description:
          "We may well have some type of insurance that we elect to pay for, and we may pay for that annually or on a monthly basis and yet we may never have to use it. If we do use that insurance at some point, we rest easy in the knowledge that the insurance will take care of the resulting costs. If your vehicle does suffer from a mechanical or electrical failure and you have a " + MyTheme.pageTitle + " warranty in place, you may well find that the cost of those repairs will be 100% covered by us, but please bear in mind that the total costs might exceed the cost of the " + MyTheme.pageTitle + " warranty you have paid for. Please look at the Claim Limit options available.",
      },
      {
        title: "Expenses other than an actual repair:",
        description:
          "Your vehicle may not be able to be repaired on the same day. Depending on the failure it may well be out of action for a few days or even weeks. " + MyTheme.pageTitle + "`s warranty will cover related costs beyond the repair, including car hire or overnight accommodation. Please see what is available under our “Additional Benefits” section in the terms and conditions.",
      },
    ],
    competition: [
      {
        title: "Wear and Tear:",
        description:
          "Wear and Tear cover is available as an upgrade and (if selected and the additional premium is paid), means that if a part breaks, or fails, due to the mileage or running of the vehicle, it is typically considered as failure due to “Wear & Tear” and it is the expected deterioration or performance of a part due to the cars age or mileage. Apart from burnt or worn-out clutch parts, all our warranties include cover for Wear and Tear, after the initial exclusion period.",
      },
      {
        title: "Boost:",
        description:
          "Boost cover is available as an upgrade on all our warranties and (if selected and the additional premium is paid), We would cover the Battery, Catalytic Convertor,  Satellite Navigation system, In-car Entertainment and Diesel Particulate Filter.",
      },
    ],
    mbiBackedInsurance: [
      {
        text: "FSCS Protected & Insurance backed by an A-rated Insurer.",
      },
      {
        text: "Cover starts on day one.",
      },
      {
        text: "Cover available for Car, Light Commercial Vehicle, Motorcycle, Moped, Motorhome or Caravan.",
      },
      {
        text: "Cover is for All mechanical and all electrical failure – called “Elite” on Car / LCV or “Gold” on Motorhome, Caravan, Motorcycle or Moped.",
      },
      {
        text: "Cover for wear and tear and Boost Cover are available as an upgrade for an additional premium.",
      },
      {
        text: "Partial or no service history prior to inception is not a problem.",
      },
      {
        text: "All Mechanical and Electrical cover for vehicles up to 10 years / 100,000 miles.",
      },
      {
        text: "No age or mileage limit once cover starts.",
      },
      {
        text: "30 days cooling off period – subject to no claim having been made.",
      },
      {
        text: "Cover available for 1, 2 or 3 years.",
      },
      {
        text: "Pay in full or over 10 months at 18.41% APR, with a small deposit.",
      },
      {
        text: "All claims settled directly to our approved repairers.",
      },
    ],
  },
];

export const termsPageContent = [
  {
    title: " INTRODUCTION",
    subtitle:
      `We are an online insurance comparison site offering access to a number of insurance products and services. Some of these products and services are offered via third party websites, which are linked to our website. Where this is the case, you will be bound by the third party's terms and conditions. In these Terms and Conditions, "we", "our" and "us" means Loyalty Cover Software Limited and "you" and "your" means any person who uses our website. If you are unsure about any aspect of these Terms & Conditions, or have any questions regarding our relationship with you, please contact Us, using the details on Our Website.`,
    description: "",
    content: [
      {
        subtitle: "1. About our insurance services ",
        
      },
      {
        subtitle: " ",
        description: `We provide an independent and online insurance products search engine that has been designed to save you time and money. Not only do we strive to find you the most competitive quotes for insurance products and services, we also aim to provide you with the information you need to make an informed choice on which policy or product best suits your needs.`,
      },
      {
        subtitle: " ",
        description: `We compare insurance products from a range of insurers and intermediaries.`
      },
      {
        subtitle: " ",
        description: `We will ask you some questions and produce a list of quotations based on the answers you give and provide the information you need to help decide which is the right policy or product for you. This will enable you to make your own choice about how to proceed. We don't offer advice or make recommendations.`
      },
      {
        subtitle: `2. How are we paid?`,
      },
      {
        subtitle: " ",
        description: `You will not be charged for using our website. Instead, when you use our website to buy a product or service from a third party, that third party pays a commission to us.`
      },
    ],
  },
  {
    title: " ABOUT LOYALTY COVER SOFTWARE LIMITED",
    subtitle: ``,
    description: ``,
    content: [
      {
        subtitle: 'The Company'
      },
      {
        subtitle: " ",
        description: `Loyalty Cover Software Limited is registered in England and Wales (Company number 14848807) and our registered address is French Farm Hurley Lane, Hurley, Maidenhead, England, SL6 5LL.`
      },
      {
        subtitle: " ",
        description: `We are an Introducer Appointed Representative (IAR) of Rest Ashored Limited who are authorised and regulated by the Financial Conduct Authority (FCA) under reference number 732530. Our FCA reference number is 999485.`
      },
      {
        subtitle: " ",
        description: `You can check this on the Financial Services Register by visiting the FCA's website http://www.fca.org.uk/register† or by contacting the FCA on 0800 111 6768.`
      }
    ],
  },
  {
    title: " USE OF OUR WEBSITE",
    subtitle: ``,
    description: ``,
    content: [
      {
        subtitle: "1. ",
        description: `Our website allows you to access our independent online service which compares a number of insurance products and services.`,
      },
      {
        subtitle: "2. ",
        description: `You are only permitted to use our website if you are a UK resident, and you are using it for personal use. Commercial use of our website is strictly prohibited, and we reserve the right to take steps - including legal action - against any person or company who uses our website or services for commercial use or in any other unauthorised manner. Unauthorised use includes (but is not limited to) breach of copyright, processing multiple quotes for other customers or companies, and other companies checking their prices against those presented on this website.`,
      },
      {
        subtitle: "3. ",
        description: `You are not permitted to do anything that may affect the security of our website, or any information or material stored within it.`,
      },
      {
        subtitle: "4. ",
        description: `You may not operate any link to our website without first obtaining our written permission. Any such permission may be revoked without notice, and we reserve the right to require you to remove any link immediately and at any time.`,
      },
    ],
  },
  {
    title: " WHAT ARE YOUR RESPONSIBILITIES?",
    subtitle: ``,
    description: ``,
    content: [
      {
        subtitle: "1. Insurance obligations",
        
      },
      {
        subtitle: " ",
        description: `We will provide you with quotations based on the information you have provided to us. Where we ask questions to help source quotations, you are responsible for:`,
      },
      {
        subtitle: "a. ",
        description: `Answering all questions honestly, completely and to the best of your knowledge, and for ensuring that any assumptions made are correct.`,
      },
      {
        subtitle: "b. ",
        description: `Failure to do so may lead to your insurance policy being invalid or an increase in cost. You may also be liable for any third-party costs in the event of an accident.`
      },
      {
        subtitle: "1. ",
        description: `In order to accept a quotation provided by us you will need to link to the underlying insurance company or intermediary website and retrieve your details or, where applicable, contact them by phone.`
      },
      {
        subtitle: "2. ",
        description: `Before purchasing a policy, it is extremely important that all the information the insurance provider holds on you is accurate. You should therefore ensure that all information you provide to us is complete and accurate. Neither we nor any insurance providers will check this information for you. Again, failure to do this may lead to your insurance being invalid or an increase in the cost of that policy.`
      },
      {
        subtitle: "3. ",
        description: `It is important that you read the insurance provider's terms and conditions to check that you understand and agree with them. The insurance company's terms and conditions are not the same as ours and they will be the terms you agree to when you accept a quotation.`
      },
      {
        subtitle: "4. ",
        description: `It is important that you read all insurance documents issued to you and ensure that you are aware of the cover, limits and other terms that apply. Failure to comply with the insurer's terms and conditions could invalidate your policy.`
      },
      {
        subtitle: "5. ",
        description: `You should also check that all your details on the policy documents are correct, including any assumptions that may have been made on your behalf by the insurance provider. If the information supplied by you is not correct, it is your responsibility to notify the insurance company. Failure to inform the insurer of any errors or missing information could invalidate your policy.`
      },
      {
        subtitle: "2. Other obligations"
      },
      {
        subtitle: "1. ",
        description: `You must keep any passwords you create confidential, and it must not be disclosed to or shared with anyone. You are solely responsible for all use of your account on our website.`
      },
      {
        subtitle: "2. ",
        description: `Where you provide information about any other individual (e.g., a named driver) you are confirming to us that you have their authority to do so.`
      },
      {
        subtitle: "3. ",
        description: `You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our website, the server on which our website is stored, or any server, computer or database connected to it.`
      },
    ],
  },
  {
    title: " WHAT ARE OUR RESPONSIBILITIES?",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "We are responsible to you for:",
        
      },
      {
        subtitle: "1. ",
        description: `any losses you suffer to the extent that they have been caused by errors in our price comparison technology; and`,
      },
      {
        subtitle: "2. ",
        description: `any claim you may have against us for a personal injury or in respect of your death if caused by our negligence; and`
      },
      {
        subtitle: "3. ",
        description: `any statements made by our employees to you which are not true statements of fact, and which are made by that employee with fraudulent intent; and`
      },
      {
        subtitle: "4. ",
        description: `any statements made by our employees to you which are misleading or untrue.`
      },
      {
        subtitle: "5. ",
        description: `Except in the case of (ii) or (iii) above (where our liability to you is unlimited), our liability to you will be limited to the upper level of our insurance limit which may vary from time to time.`
      },
    ],
  },
  {
    title: " WHAT ARE WE NOT RESPONSIBLE TO YOU FOR:",
    subtitle:
      ``,
    content: [
      {
        subtitle: "1. ",
        description: `any reliance that you may place on any material or commentary posted on our website. Please note that nothing contained in our website, or the material published on it is intended to amount to advice on which you should rely; or`
      },
      {
        subtitle: "2. ",
        description: `any losses you suffer because the information you put into our website is inaccurate or incomplete; or`
      },
      {
        subtitle: "3. ",
        description: `any losses you suffer if the information you put into our website is accurate, but the insurer's website has not accurately reflected that information because its system is not working correctly; or`
      },
      {
        subtitle: "4. ",
        description: `any losses you suffer if you misrepresent any fact which may influence the insurer's decision to accept the risk, or the terms offered; or`
      },
      {
        subtitle: "5. ",
        description: `any losses you suffer because you cannot use our website at any time; or`
      },
      {
        subtitle: "6. ",
        description: `any losses you suffer through connecting to any linked third-party websites or any statements, information, content, products or services that are published on, or may be accessible from, any linked third-party website and we do not guarantee that they are free from viruses or anything else that could be infectious or destructive; or`
      },
      {
        subtitle: "7. ",
        description: `any errors in or omissions from our website; or`
      },
      {
        subtitle: "8. ",
        description: `any losses you may suffer by relying on any commentary, postings, or reviews (of our services or that of our partners) on our website; or`
      },
      {
        subtitle: "9. ",
        description: `any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of our website or to your downloading of any material posted on it, or on any website linked to it; or`
      },
      {
        subtitle: "10. ",
        description: `the privacy policies and practices of other linked third-party websites, even if you access them using links from our website; or`
      },
      {
        subtitle: "11. ",
        description: `any unauthorised access or loss of personal information that is beyond our control.`
      },
    ],
  },

  {
    title: " COMPLAINTS",
    subtitle: "We set ourselves very high standards and do our utmost to maintain these high standards in everything we do. In rare situations where you may feel those standards have not been maintained we would like you to tell us as this is the only way we will be able to improve our service.",
    description: "",
    content: [
      {
        subtitle: "How do you do this?",
        
      },
      {
        subtitle: " ",
        description: `Please contact us in the following ways:`,
      },
      {
        subtitle: " ",
        description: `Email: complaints@loyaltycoversoftware.com`
      },
      {
        subtitle: " ",
        description: `In Writing: Loyalty Cover Software Limited / French Farm, Hurley Lane, Hurley, Maidenhead, England, SL6 5LL`
      },
      {
        subtitle: " ",
        description: `info@loyaltycoversoftware.co.uk`
      },
      {
        subtitle: " ",
        description: `Please note that our office opening hours are Monday to Friday 9am to 5.00pm, excluding bank holidays. Calls may be recorded for training, quality and monitoring purposes.`
      },
      {
        subtitle: " ",
        description: `If your complaint relates to an insurance company or insurance policy, we will let you know that we have forwarded your complaint to the insurance company and will supply you with their contact details. The insurance company will then reply to your complaint directly. If you are still dissatisfied then you may be entitled to refer your complaint to the Financial Ombudsman Service (FOS).`
      },
      {
        subtitle: " ",
        description: `The insurance companies and intermediaries that we work with are covered by the Financial Services Compensation Scheme (FSCS). You may be entitled to compensation from the scheme if they cannot meet their obligations. This depends on the type of business and the circumstances of the claim. If you are eligible to claim from the FSCS, compensation is available as follows:`
      },
      {
        subtitle: " ",
        description: `Insurance advising and arranging is covered for 90% of the claim, without any upper limit.`
      },
      {
        subtitle: " ",
        description: `Further information about compensation scheme arrangements is available from the FSCS by calling 0800 678 1100 or 020 7741 4100 or by visiting their website www.fscs.org.uk.`
      },
    ],
  },

  {
    title: " INTELLECTUAL PROPERTY",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: `1. `,
        description: `We are the owner or the licensee of all intellectual property rights in our website and in the material published on it. This includes, but is not limited to, all trademarks, logos, business names, trading names, design rights, database rights, copyright, and any other intellectual property rights.`,
      },
      {
        subtitle: `2. `,
        description:  `You may print, download or store extracts from our website provided always that:`,
      },
      {
        subtitle: " - ",
        description: `It is used solely for your personal, non-commercial use;`
      },
      {
        subtitle: " - ",
        description: `You will not copy, transmit or distribute them without our prior written approval;`
      },
      {
        subtitle: " - ",
        description: `All copies must retain any copyright or other intellectual property notices contained within the original material;`
      },
      {
        subtitle: " - ",
        description: `You acknowledge that all images displayed on our website are protected by copyright and may not be reproduced or appropriated in any manner without the prior written consent of their respective owner(s);`
      },
      {
        subtitle: " - ",
        description: `No logos, trademarks or service marks displayed on our website may be printed or downloaded, except as part of the text of which they form part;`
      },
      {
        subtitle: " - ",
        description: `You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences, logos, designs or any graphics separately from any accompanying text; and`
      },
      {
        subtitle: " - ",
        description: `Our status (and that of any identified contributors) as the authors of material on our website must always be acknowledged.`
      },
      {
        subtitle: "3. ",
        description: ` If you print off, copy, or download any part of our website in breach of these terms of use, your right to use our website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made (in whatever format).`
      },
    ],
  },
  {
    title: " PRIVACY POLICY",
    subtitle:
      `Loyalty Cover Software Limited ("we", "our" or "us") understand how important your privacy is and we take its security seriously. Our Privacy Policy, is shown at the bottom of our website home page. This Privacy Policy should be read in conjunction with our Terms and Conditions and our Cookie Policy.`,
    description: "",
    content: [],
  },
  {
    title: " LEGAL",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "1. ",
        description: `These Terms and Conditions apply to your use of our website.`
      },
      {
        subtitle: "2. ",
        description: `By accessing our website, you agree to be bound by these Terms and Conditions, together with our Privacy Policy and Cookie Policy. If you do not wish to be bound by them then you should stop using our website immediately.`
      },
      {
        subtitle: "3. ",
        description: `We reserve the right to change these Terms and Conditions from time to time and without notice. You should therefore check these Terms and Conditions each time you visit our website.`
      },
      {
        subtitle: "4. ",
        description: `If any part of these Terms and Conditions is subsequently considered to be illegal, invalid or unenforceable then that provision should be treated as having been deleted without affecting the validity of the remaining terms.`
      },
      {
        subtitle: "5. ",
        description: `These Terms and Conditions (together with our Privacy Policy and Cookie Policy) represent the entire agreement between you and us relating to your use of our website. These terms also supercede any previous agreements, including previous terms and conditions of our website.`
      },
      {
        subtitle: "6. ",
        description: `These Terms and Conditions are subject to the laws of England and Wales and the exclusive jurisdiction of their Courts.`
      },
    ]
  }
];

export const cookiePageContent = [
  {
    title: "Definitions and Interpretation",
    subtitle:
      "In this Policy the following terms shall have the following meanings:",
    description: "",
    content: [
      {
        subtitle: "Data - ",
        description: `means collectively all information that you submit to the ”Loyalty Cover Software Limited” via the Website. This definition shall, where applicable, incorporate the definitions provided in the Data Protection Act 1998;`,
      },
      {
        subtitle: "Cookie - ",
        description: `means a small text file placed on your computer by this Website when you
        visit certain parts of the Website and/or when you use certain features of the Website.
        Details of the cookies used by this Website are set out in Clause 12.`,
      },
      {
        subtitle: `”Loyalty Cover Software Limited ”`,
        description: `means the company who are an Introducer
        Appointed Representative (IAR) of Rest Ashored Ltd who are Authorised and
        Regulated by the Financial Conduct Authority. Their Financial Services Register
        Number (FRN) is 732530. You may check this on the FCA Register by visiting the FCA’s
        website, www.fca.org.uk/register or by contacting the FCA on 0800 111 6768. Loyalty
        Cover Software Limited is registered in England under Company No. 14848807. ICO
        Data number 00013444359`,
      },
      {
        subtitle: "UK and EU Cookie Law - ",
        description: `means the Privacy and Electronic Communications (EC
          Directive) Regulations 2003 as amended by the Privacy and Electronic Communications
          (EC Directive) (Amendment) Regulations 2011;`,
      },
      {
        subtitle: "User, You - ",
        description: `means any third party that accesses the Website and is not employed by
        ”Loyalty Cover Software Limited” and acting in the course of their employment.
        `,
      },
      {
        subtitle: "Website - ",
        description: `means the website that you are currently using (loyaltycoversoftware.com) and any
        sub-domains of this site (e.g. subdomain.loyaltycoversoftware.com) unless expressly excluded by
        their own terms and conditions.`,
      },
      {
        subtitle: "We, Us - ",
        description: `”Loyalty Cover Software Limited”`,
      },
      {
        subtitle: "Data Controller - ",
        description: `”Loyalty Cover Software Limited”`,
      },
    ],
  },
  {
    title: "Scope of this Policy",
    subtitle:
      `This Policy applies only to the actions of ”Loyalty Cover Software Limited” and Users
      with respect to this Website. It does not extend to any websites that can be accessed
      from this Website including, but not limited to, any links we may provide to social
      media websites.`,
    description: ``,
    content: [],
  },
  {
    title: "Data Collected",
    subtitle: `
    Without limitation, any of the following Data may be collected by this Website from
    time to time:    
     `,
    description: `We are the Data Controller for the data You provide to Us. We need to use Your data
    in order to arrange Your insurance and associated products. You are obliged to provide
    information without which We will be unable to provide a service to You. Any personal
    information provided by You may be held by the Insurer in relation to Your insurance
    cover. It may be used by Our relevant staff in making a decision concerning Your
    insurance and for the purpose of servicing Your cover and administering claims.
    Information may be passed to loss adjusters, solicitors, reinsurers, or other service
    providers for these purposes. We may obtain information about You from credit
    reference agencies, fraud prevention agencies and others to check Your credit status
    and identity. The agencies will record Our enquiries, which may be seen by other
    companies who make their own credit enquiries. If You provide false or inaccurate
    information and We suspect fraud, We will record this.`,
    content: [
      {
        subtitle: "",
        description: `name`,
      },
      {
        subtitle: "",
        description: `date of birth`,
      },
      {
        subtitle: "",
        description: `vehicle details`,
      },
      {
        subtitle: "",
        description: `job title/study level`,
      },
      {
        subtitle: "",
        description: `Employer`,
      },
      {
        subtitle: "",
        description: `contact information such as email addresses and telephone numbers`,
      },
      {
        subtitle: "",
        description: `demographic information such as post code, preferences and interests`,
      },
      {
        subtitle: "",
        description: `financial information such as credit / debit card numbers`,
      },
      {
        subtitle: "",
        description: `IP address (automatically collected)`,
      },
      {
        subtitle: "",
        description: `web browser type and version (automatically collected)`,
      },
      {
        subtitle: "",
        description: `operating system (automatically collected)`,
      },
      {
        subtitle: "",
        description: `a list of URLs starting with a referring site, your activity on this Website, and the site you exit to (automatically collected);`,
      },
      {
        subtitle: "",
        description: `When you obtain a quote but do not purchase a policy we will store your information and may contact you to`,
      },
    ],
  },
  {
    title: "We and other organisations may use these records to:",
    subtitle: `
    
     `,
    description: `We process all data in the UK but where We need to disclose data to parties outside
    the European Economic Area (EEA) We will take reasonable steps to ensure the privacy
    of Your data. In order to protect Our legal position, We will retain Your data for a
    minimum of 7 years. We have a Data Protection regime in place to oversee the effective
    and secure processing of Your data. Under GDPR legislation, You can ask Us for a copy
    of the data We hold, have it corrected, sent to a third party or deleted (subject to Our
    need to hold data for legal reasons). We will not make Your personal details available
    to any companies to use for their own marketing purposes. If You wish to complain
    about how We have handled Your data, You can contact Us and We will investigate
    the matter. If You are not satisfied with Our response or believe We are processing
    Your data incorrectly You can complain to the Information Commissioner’s Office,
    Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF Tel: 0303 123 1113.`,
    content: [
      {
        subtitle: "a.",
        description: ` Help make decisions on insurance proposals and insurance claims, for You and members of Your household.`,
      },
      {
        subtitle: "b.",
        description: `Trace debtors, recover debt, prevent fraud, and manage Your insurance policies`,
      },
      {
        subtitle: "c. ",
        description: `Check Your identity to prevent money laundering, unless You furnish Us with satisfactory proof of identity.`,
      },
    ],
  },
  {
    title: "Third Party Websites and Services",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `”Loyalty Cover Software Limited” may, from time to time, employ the services of
        other parties for dealing with matters that may include, but are not limited to, payment
        processing, delivery of purchased items, search engine facilities, advertising and
        marketing. The providers of such services have access to certain personal Data
        provided by Users of this Website.`,
      },
      {
        subtitle: "",
        description: `Any Data used by such parties is used only to the extent required by them to
        perform the services that ”Loyalty Cover Software Limited” requests. Any use for other
        purposes is strictly prohibited. Furthermore, any Data that is processed by third parties
        shall be processed within the terms of this Policy and in accordance with the Data
        Protection Act 1998.`,
      },
    ],
  },
  {
    title: "Links to Other Websites",
    subtitle:
      `This Website may, from time to time, provide links to other websites. ”Loyalty Cover
      Software Limited” has no control over such websites and is in no way responsible for
      the content thereof. This Policy does not extend to your use of such websites. Users
      are advised to read the privacy policy or statement of other websites prior to using
      them.`,
    content: [],
  },

  {
    title: "Changes of Business Ownership and Control",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `”Loyalty Cover Software Limited” may, from time to time, expand or reduce our
        business and this may involve the sale and/or the transfer of control of all or part of
        ”Loyalty Cover Software Limited”. Data provided by Users will, where it is relevant to
        any part of our business so transferred, be transferred along with that part and the
        new owner or newly controlling party will, under the terms of this Policy, be permitted
        to use the Data for the purposes for which it was originally supplied to us.`,
      },
      {
        subtitle: "",
        description: `In the event that any Data submitted by Users is to be transferred in such a manner, you will not be contacted in advance and informed of the changes.`,
      },
    ],
  },

  {
    title: "Your Right to Withhold Information",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: ``,
        description: `You may access certain areas of the Website without providing any Data at all. However, to use all features and functions available on the Website you may be required to submit certain Data.`,
      },
      {
        subtitle: ``,
        description: `You may restrict your internet browser’s use of Cookies. For more information see Clause 12.`,
      },
    ],
  },
  {
    title: " Accessing your own Data",
    subtitle:
      `You have the right to ask for a copy of any of your personal Data held by ”Loyalty
      Cover Software Limited” (where such data is held) by contacting us in writing at
      ”Loyalty Cover Software Limited”, French Farm Hurley Lane, Hurley, Maidenhead, England, SL6 5LL.`,
    description: "",
    content: [],
  },
  {
    title: "Security",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `Data security is of great importance to ”Loyalty Cover Software Limited” and to
        protect your Data we have put in place suitable physical, electronic and managerial
        procedures to safeguard and secure Data collected via this Website.`,
      },
    ],
  },
  {
    title: "Cookies",
    subtitle: "",
    description: "",
    content: [
      {
        subtitle: "",
        description: `This Website may place and access certain Cookies on your computer. ”Loyalty
        Cover Software Limited” uses Cookies to improve your experience of using the Website
        and to improve our range of products and services. ”Loyalty Cover Software Limited”
        has carefully chosen these Cookies and has taken steps to ensure that your privacy is
        protected and respected at all times.`,
      },
      {
        subtitle: "",
        description: `All Cookies used by this Website are used in accordance with current UK and EU Cookie Law.`,
      },
      {
        subtitle: "",
        description: `[Certain features of the Website depend upon Cookies to function. UK and EU Cookie Law deems these Cookies to be strictly necessary]. These Cookies are shown below. Your consent will not be sought to place these Cookies. You may still block these cookies by changing your internet browser’s settings.`,
      },
      {
        subtitle: "",
        description: `You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser.`,
      },
      {
        subtitle: "",
        description: ` You can choose to delete Cookies at any time however you may lose any information that enables you to access the Website more quickly and efficiently including, but not limited to, personalisation settings.`,
      },
      {
        subtitle: "",
        description: `It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy settings.`,
      },
    ],
  },
  {
    title: "Changes to this Policy",
    subtitle:
      `”Loyalty Cover Software Limited” reserves the right to change this Policy as we may
      deem necessary from time to time or as may be required by law. Any changes will be
      immediately posted on the Website, and you are deemed to have accepted the terms
      of the Policy on your first use of the Website following the alterations.`,
    description: "",
    content: [],
  },
];

export const secondaryPagesHeaderImages = [
  {
    origin: "Return to Invoice",
    image: gap26,
  },
  {
    origin: "Finance Gap",
    image: gap26,
  },
  {
    origin: "Alloy Wheel",
    image: alloy26,
  },

  {
    origin: "Tyre",
    image: tyre26,
  },
  {
    origin: "MBI",
    image: mbi26,
  },
  {
    origin: "Cosmetic Repair",
    image: cosmetic26,
  },

  {
    origin: "Excess",
    image: mbi26,
  },
];

export const fullParagraphContent = {
  "About Us": [
    {
      title: `Decades of Experience `,
      description: `Just think how good you’d be at something if you’d been doing it for over 20 years? 
  Our team of car-lovers have been saving people money and time for decades, using 
  their experience and knowledge across all kinds of insurance markets in the UK and 
  across Europe. 
  That’s why we know how to get the best deal for you and your vehicle.
  We’ve used all that knowledge to create a helpful range of policies to keep you fully 
  covered.`,
      image: aboutUs2,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    },
    {
      title: `We Believe in Giving Back`,
      description: `Responsibility is an important word to us here at ` + MyTheme.pageTitle + `. 
  Not only are we responsible for your car’s every need, but we do our part in being 
  responsible for nurturing our planet. 
  That’s why for every policy sold, we plant one tree. 
  If you think about it, that’s A LOT of trees. 
  Especially when you consider our policy prices. 
  Want to help plant a tree today? 
  `,
      image: giving,
      imagePosition: "right",
      button: "abc,"
    },
  ],

  "Alloy Wheel": [
    {
      title: "All cars welcome, all costs covered",
      description: "At " + MyTheme.pageTitle + ", we cover ALL accidental damage repair costs, giving you the ultimate peace of mind when it comes to your vehicle’s alloy wheels.",
      image: alloy_paragraph_image,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],

  "Homepage": [
    {
      title: "Protect yourself and your vehicle against unforeseen costs",
      description: "At " + MyTheme.pageTitle + ", we are here to offer you the ultimate peace of mind when it comes to your vehicle.",
      image: car_ins_homepage,
      imagePosition: "left",
      button: "Get A Quote",
    },
    {
      title: "Clear answers to your questions",
      description: "We save you money, we save you time, but we also save you the hassle. Don’t fret about anything you’re unsure of. If you have a question, anything at all, we have a dedicated team of customer representatives on hand to answer them.",
      image: car_ins_par_homepage,
      imagePosition: "right",
      phone: "0161 505 1420",
    }
  ],
  "TreePlantedPage": [
    {
      title: `Why are trees important?`,
      description: `Trees help clean the air we breathe, filter the water we drink, and provide habitat to over 80% of the world’s terrestrial biodiversity. Forests provide jobs to over 1.6 billion people, absorb harmful carbon from the atmosphere, and are key ingredients in 25% of all medicines. Have you ever taken an Aspirin? It comes from the bark of a tree!`,
      image: importanceOfTrees,
      imagePosition: "left",
    },
    {
      title: `Air`,
      description: `Trees are like the vacuums of our planet. Through their leaves and bark, they absorb harmful pollutants and release clean oxygen for us to breathe. In urban environments, trees absorb pollutant gases like nitrogen oxides, ozone, and carbon monoxide, and sweep up particles like dust and smoke. Increasing levels of carbon dioxide caused by deforestation and fossil fuel combustion trap heat in the atmosphere. Healthy, strong trees act as carbon sinks, absorbing atmospheric carbon dioxide and reducing the effects of climate change.`,
      image: air,
      imagePosition: "right",
    },
    {
      title: `Water`,
      description: `Trees play a key role in capturing rainwater and reducing the risk of natural disasters like floods and landslides. Their intricate root systems act like filters, removing pollutants and slowing down the water’s absorption into the soil. This process prevents harmful waterslide erosion and reduces the risk of oversaturation and flooding. According to the UN Food and Agriculture Association, a mature evergreen tree can intercept more than 15,000 litres of water every year.`,
      image: water,
      imagePosition: "left",
      halfPinkPig: "yes",
    },
    {
      title: `Biodiversity`,
      description: `A single tree can be home to hundreds of species of insect, fungi, moss, mammals, and plants. Depending on the kind of food and shelter they need, different forest animals require different types of habitat. Without trees, forest creatures would have nowhere to call home.`,
      image: biodiversity,
      imagePosition: "right",
    },
    {
      title: `Social impact`,
      description: `From arborists to loggers and researchers, the job opportunities provided by the forestry industry are endless. We don’t just rely on trees for work, though. Sustainable tree farming provides timber to build homes and shelters, and wood to burn for cooking and heating. Food-producing trees provide fruit, nuts, berries, and leaves for consumption by both humans and animals, and pack a powerful nutritional punch.`,
      image: socialImpact,
      imagePosition: "left",
    },
    {
      title: `Health`,
      description: `Did you know that hospital patients with rooms overlooking trees recover faster than those without the same view? It’s impossible to ignore that feeling of elation you get while walking through a calm, quiet forest. Trees help reduce stress and anxiety, and allow us to reconnect with nature. In addition, shade provided by tree coverage helps protect our skin from the ever-increasing harshness of the sun.`,
      image: health,
      imagePosition: "right",
      halfBluePig: "yes",
    },
    {
      title: `Climate`,
      description: `Trees help cool the planet by sucking in and storing harmful greenhouse gases like carbon dioxide into their trunks, branches, and leaves — and releasing oxygen back into the atmosphere. In cities, trees can reduce ambient temperatures by up to 8° Celsius. With more than 50% of the world’s population living in cities — a number expected to increase to 66% by the year 2050 — pollution and overheating are becoming a real threat. Fortunately, a mature tree can absorb an average of 48 lbs of carbon dioxide per year, making cities a healthier, safer place to live.`,
      image: climate,
      imagePosition: "left",
    },
  ],
  "Tyre": [
    {
      title: `Protect against tyre repair costs`,
      description: `Ease the pain of punctures and cover the cost of repair to your damaged tyres with ` + MyTheme.pageTitle + `’s insurance.`,
      image: tyreInsurance2,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],
  "Cosmetic Repair": [
    {
      title: `Great-Value SMART Repair Insurance`,
      description: `Insurance for Small Medium Area Repair Technology (or SMART Repair) can be very expensive, especially when purchased from your local car dealer. \n
      That’s where `+ MyTheme.pageTitle + ` comes in, like our name suggests, to help you cut costs and offer you vehicle repair by one of our authorised specialists, helping to keep your car in showroom condition.`,
      image: cosmeticInsurance3,
      imagePosition: "left",
      pig: "yes",
    }
  ],
  "Excess": [
    {
      title: `What does excess insurance cover?`,
      description: `Put simply, whenever you have to make claim under your motor insurance policy, we will pay for any excess costs incurred, unless it exceeds your claims limit.`,
      image: excessInsurance3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],
  "Finance Gap": [
    {
      title: `Protect against depreciation`,
      description: `As you will know, the moment a vehicle is driven out off the showroom, its value begins to drop like a stone. In fact, the average vehicle is predicted to depreciate by up to 50% within the first three years alone.`,
      image: gapInsurance3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    },
    {
      title: `Comprehensive cover in the event of a write-off`,
      description: `The amount that you are offered in settlement can be, and often is, thousands of pounds less than the original amount that you paid. ` + MyTheme.pageTitle + ` provides a solution to this problem, offering comprehensive cover which significantly boosts your payment in the event of a write-off.`,
      image: newImgwebp,
      imagePosition: "right",
    }
  ],
  "MBI": [
    {
      title: `Cover against mechanical & electrical failures`,
      description: `Our vehicle warranty is there to keep you fully covered. That’s why we take care of costs for both parts and labour in the event of any mechanical or electric faults. Remember, our vehicle warranty is much cheaper than the cost of repairs, so take advantage today.`,
      image: vehicleWarranty3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    },
    {
      title: `Remove the stress, let us take care of your repair`,
      description: `We can’t predict the future, but ` + MyTheme.pageTitle + ` accounts for all possibilities, to give you the ultimate peace of mind in any given situation. We know it’s stressful when your vehicle lets you down, so we leave no stone unturned in making it as comfortable as possible for you in such an event.`,
      image: vehicleWarranty4,
      imagePosition: "right",
    }
  ],
  "Wedding": [
    {
      title: `Do I Need Wedding Insurance?`,
      description: `Wedding insurance from ` + MyTheme.pageTitle + ` is there to keep you protected. The last thing you want is something to go wrong on your big day, but if it does, don’t make it worse by being stressed and unnecessarily out of pocket.`,
      image: weddingInsurance3,
      imagePosition: "left",
      button: "abc",
      pig: "yes",
    }
  ],
  "Star": [
    {
      title: "Star Rating",
      description: [
        {
          text: `To make choosing between cover levels easier to understand, we have introduced a “Star
          Rating” guide for each product displayed. The “Stars” mean as follows:`
        },
        
        {
          text: `1 – star – A lower level of “Listed Component” mechanical and electrical warranty,
          more suited to the older vehicles, without Boost or Wear and Tear cover. A variety of
          claim limits and labour rates available.`
        },
        {
          text: `2 – star – A more comprehensive “Listed Component” mechanical and electrical
          Warranty without Boost or Wear and Tear cover. A variety of claim limits and labour
          rates available.`
        },
        {
          text: `3 – star – A “Listed Component” mechanical and electrical Warranty with both Boost*
          and Wear and Tear** cover included. A variety of claim limits and labour rates
          available.`
        },
        {
          text: `4 – star – An “All Inclusive” protection covering all mechanical and electrical
          components other than those listed in the exclusions section, but without Boost or
          Wear and Tear cover. A variety of claim limits and labour rates available.`
        },
        {
          text: `5 – star – “All Inclusive” protection covering all mechanical and electrical components
          other than those listed in the exclusions section, with Boost* and Wear and Tear**
          cover included. A variety of claim limits and labour rates available.`
        },
        {
          text: `* Check what is covered under Boost as this varies by insurer.`
        },
        {
          text: `** Check for any initial exclusion period on Wear & Tear as this varies by insurer.`
        },
      ],
      image: vehicleWarranty4,
      imagePosition: "left",
    },
    {
      title: "Insurance backed products",
      description: [
        {
          text: `Some Warranty products offered on comparison sites and provider websites are not back by an insurance company and you should check to ensure that the products are insured and that the insurer and provider are regulated by the FCA so that you will have FSCS protection if everything goes wrong`
        },
        {
          text: `All of the providers on our comparison site are regulated by the Financial Conduct Authority (FCA) and all the products are fully insured. This means that you would be able to ask the Financial Ombudsman for help if you have a complaint about the provider and if your insurer goes into administration  you will benefit from Financial Services Compensation Scheme (FSCS).`
        },
      ],
      image: car_ins_homepage,
      imagePosition: "right",
    },
  ]
}
// export const treePlantedPage = [
//   {
//     title: `Why are trees important?`,
//     description: `Trees help clean the air we breathe, filter the water we drink, and provide habitat to over 80% of the world’s terrestrial biodiversity. Forests provide jobs to over 1.6 billion people, absorb harmful carbon from the atmosphere, and are key ingredients in 25% of all medicines. Have you ever taken an Aspirin? It comes from the bark of a tree!`,
//     image: importanceOfTrees,
//   },
//   {
//     title: `Air`,
//     description: `Trees are like the vacuums of our planet. Through their leaves and bark, they absorb harmful pollutants and release clean oxygen for us to breathe. In urban environments, trees absorb pollutant gases like nitrogen oxides, ozone, and carbon monoxide, and sweep up particles like dust and smoke. Increasing levels of carbon dioxide caused by deforestation and fossil fuel combustion trap heat in the atmosphere. Healthy, strong trees act as carbon sinks, absorbing atmospheric carbon dioxide and reducing the effects of climate change.`,
//     image: air,
//   },
//   {
//     title: `Water`,
//     description: `Trees play a key role in capturing rainwater and reducing the risk of natural disasters like floods and landslides. Their intricate root systems act like filters, removing pollutants and slowing down the water’s absorption into the soil. This process prevents harmful waterslide erosion and reduces the risk of oversaturation and flooding. According to the UN Food and Agriculture Association, a mature evergreen tree can intercept more than 15,000 litres of water every year.`,
//     image: water,
//   },
//   {
//     title: `Biodiversity`,
//     description: `A single tree can be home to hundreds of species of insect, fungi, moss, mammals, and plants. Depending on the kind of food and shelter they need, different forest animals require different types of habitat. Without trees, forest creatures would have nowhere to call home. Young, Open Forests: These forests occur as a result of fires or logging. Shrubs, grasses, and young trees attract animals like black bears, the American goldfinch, and bluebirds in North America. Middle-Aged Forests: In middle-aged forests, taller trees begin to outgrow weaker trees and vegetation. An open canopy allows for the growth of ground vegetation prefered by animals like salamanders, elk, and tree frogs.
// Older Forests: With large trees, a complex canopy, and a highly developed understory of vegetation, old forests provide habitat for an array of animals, including bats, squirrels, and many birds`,
//     image: biodiversity,
//   },
//   {
//     title: `Social impact`,
//     description: `From arborists to loggers and researchers, the job opportunities provided by the forestry industry are endless. We don’t just rely on trees for work, though. Sustainable tree farming provides timber to build homes and shelters, and wood to burn for cooking and heating. Food-producing trees provide fruit, nuts, berries, and leaves for consumption by both humans and animals, and pack a powerful nutritional punch.`,
//     image: socialImpact,
//   },
//   {
//     title: `Health`,
//     description: `Did you know that hospital patients with rooms overlooking trees recover faster than those without the same view? It’s impossible to ignore that feeling of elation you get while walking through a calm, quiet forest. Trees help reduce stress and anxiety, and allow us to reconnect with nature. In addition, shade provided by tree coverage helps protect our skin from the ever-increasing harshness of the sun.`,
//     image: health,
//   },
//   {
//     title: `Climate`,
//     description: `Trees help cool the planet by sucking in and storing harmful greenhouse gases like carbon dioxide into their trunks, branches, and leaves — and releasing oxygen back into the atmosphere. In cities, trees can reduce ambient temperatures by up to 8° Celsius. With more than 50% of the world’s population living in cities — a number expected to increase to 66% by the year 2050 — pollution and overheating are becoming a real threat. Fortunately, a mature tree can absorb an average of 48 lbs of carbon dioxide per year, making cities a healthier, safer place to live.`,
//     image: climate,
//   },
// ];

export const treePlantedHeader = {
  description:
    "With every Policy purchased from " + MyTheme.pageTitle + " a Tree is Planted.",
  background: forestHeader,
  checks: [
    {
      check: "Save Money",
    },
    {
      check: "Plant a tree",
    },
    {
      check: "Heal the planet",
    },
  ],
  imgwebp: imgTree,
  imgpng: imgTreepng,
};

export const weddingHeader = {
  description:
    "Giving peace of mind on your big day.",
  checks: [
    {
      check: "Protect your finances",
    },
    {
      check: "Choose the package that best suits you",
    },
    {
      check: "Five ranges of cover",
    },
    {
      check: "Complete wedding day cover",
    },
  ],
  imgwebp: imgWedding,
  imgpng: imgWeddingpng,
};

export const aboutHeader = {
  description: MyTheme.pageTitle + "’s aim is to save you both time and money on your insurance.",
  checks: [
    {
      check: "Providing products to over £180 million worth of vehicles across the UK",
    },
    {
      check: "A tree planted with every purchase",
    },
    {
      check: "Decades of experience & FSCS protected",
    },
  ],
  imgwebp: imgAboutUs,
  imgpng: imgAboutUspng,
}

export const weddingInsuranceTiles = {
  title: `What Weddings can we cover?`,
  description: `The ` + MyTheme.pageTitle + ` Wedding Insurance policies are available for any ceremony which 
creates a contract of marriage which is legally enforceable within the United Kingdom, 
including ceremonies of Civil partnership, or, for the purposes of this insurance, a 
similar single, specific event/ceremony/blessing arranged to celebrate a marriage or 
renewal of vows`,
  tiles: [
    {
      title: "Cancellation Cover",
      description: `All of our Wedding Insurance policies will provide you with cancellation and rearrangement cover should the unexpected happen, causing you to cancel or rearrange your wedding (for an insured reason) Please see page 3 of the terms and conditions for details. 

With 5 different policy levels, you can purchase a policy to suit your own needs to cover the ceremony you are planning.

Below is a list of our current policy levels and how much cancellation cover they provide

**Silver**: £6,000 Cancellation

**Gold**: £10,000 Cancellation

**Amber**: £15,000 Cancellation

**Platinum**: £20,000 Cancellation

**Diamond**: £30,000 Cancellation with an option to upgrade to £50,000`
    },
    {
      title: "Re-arrangement Cover",
      description: `In the event of the cancellation of your planned wedding, it may be that you wish to rearrange an alternative ceremony. 
All `+ MyTheme.pageTitle + ` Wedding include protection for the additional costs necessarily incurred in rearranging the Wedding and/or Wedding reception and/or Wedding services to a similar standard to the amount originally budgeted. 

This benefit is only available in the event of a valid claim for the cancellation of the Wedding, Wedding reception or Wedding services as covered under our cancellation benefit.

In respect of rearrangement, all additional costs and expenses must be notified to the claims handlers and agreed by them in advance of the rearranged wedding.

See page 3 of the terms and conditions for the claim limits applicable.`
    },
    {
      title: "Ceremonial Attire",
      description: `All ` + MyTheme.pageTitle + ` Wedding Insurance policies offer cover to protect you against the 
accidental complete loss of or severe damage to the ceremonial attire which renders 
the items unwearable and where the purchase or hire of alternatives is not possible.
Cover is for clothing and accessories of the Marrying couple, male and female 
attendants and the parents of the Marrying couple, whether hired or owned, excluding 
ceremonial swords.
The Claim Limit varies depending on the level of cover selected and ranges from 
£3,000 to £10,000. Please see page 3 of the full terms and conditions for exact details`
    },
    {
      title: "Wedding Gifts",
      description: `With our Wedding Gifts Coverage, included in all of our policies, your gifts are covered for up to 7 days before your big day and for 24 hours afterwards. The aggregate claim limit varies depending on the level of cover selected and ranges from £3,000 to £10,000 and the individual cover per item, ranging from £300 to a maximum of £1,000. The individual claim limit on cash or vouchers ranges from £200 to £1,000.`
    },
    {
      title: "Rings, Flowers, Gifts and Cake",
      description: `**Cake**- You may be surprised to hear that the average couple spends around £300 on 
the cake for their big day which in itself is a big purchase, even if it's a relatively small 
proportion of your wedding spend. All the `+ MyTheme.pageTitle + ` wedding insurance policies 
include cover so that you can benefit between £500 and £2,000* worth of protection 
for your Wedding Cake, should the unexpected happen.

**Rings**- Cover for Wedding Rings is included as standard on all wedding insurance 
policies and can provide between £500 and £2,000* per item (ring).

With the amount of time and effort you and your partner will put into finding the right 
rings for yourselves, and with the amount of money you are likely to spend on the 
perfect rings, it makes sense to insure these should the unexpected happen.

**Attendant Gifts** – Any gift that you have purchased for your wedding attendants will
have been carefully chosen by you and so it will be good to know that you will be 
covered against loss or accidental damage for the 7 days prior to and 24 hours after 
the ceremony.

**Flowers**- No wedding would be complete without stunning flower arrangements and 
eye-catching bouquets!

What might surprise you is the cost of these flowers, with many couples ending up 
spending more on their wedding flowers than they had originally budgeted for 
especially if certain exotic flowers are arranged by a florist. That's why all our Wedding 
Insurance policies include wedding flowers cover as standard.

With up to £2,000* of cover available for Wedding Flowers you can be safe in the 
knowledge that you are protected should your flowers get lost or accidentally damaged 
in the 36 hours prior to the ceremony or during the ceremony or reception themselves.`
    },
    {
      title: "Wedding Cars & Transport",
      description: `All ` + MyTheme.pageTitle + ` Wedding Insurance policies offer financial protection for unforeseen 
additional costs to arrange alternative transport, if the contracted private hire 
provider, with whom the wedding transport arrangements have been made fails to 
meet its/their contractual obligation(s) following non-appearance, breakdown or 
accident`
    },
    {
      title: "Photography and Video",
      description: `There is a long list of things that can go wrong when it comes to wedding photos 
and wedding videos. Whether it's the Professional Photographer who was booked for 
your wedding day not showing up, or the films being lost or damaged before the 
final prints are produced. All of our Wedding Insurance policies include coverage for 
Photography and Video to cover the costs incurred should you need to take/re-take 
your Wedding Photos!

All `+ MyTheme.pageTitle + ` Wedding Insurance policies offer between £3,000* and £10,000*
of Photography and Video cover, depending on the claim limit selected, so you can 
rest assured that you have ample cover should the unexpected happen!`
    },
    {
      title: "Financial Failure",
      description: `It's fair to say most couples worry about some of their wedding service suppliers 
'going under' before their big day.

Whether that's the caterers, the photographer, or any other wedding supplier, in this 
current economic environment nothing is certain. This can obviously have huge 
implications for your wedding especially if you are unable to recoup any deposits you 
may have paid.

If this does occur then, in line with the policy wording, you will be covered for any 
irrecoverable deposits and any additional costs you face in arranging an alternative, 
equivalent service between £3,000* and £15,000*. 

Cover for Financial Failure is included as standard on all Wedding Insurance policies 
because we want you to have the wedding you have always dreamed of and aren't 
left out of pocket because of something out of your control.`
    },
    {
      title: "Personal Accident",
      description: `All ` + MyTheme.pageTitle + ` Wedding Insurance policies offer cover for the marrying couple 
against personal accident, which includes the loss of sight, a limb or any permanent
disablement within 12 months of a covered accident.

The claim limit varies between £5,000 and £20,000 depending on the level of cover 
selected.`
    },
    {
      title: "Professional Counselling",
      description: `All ` + MyTheme.pageTitle + ` Wedding Insurance policies offer cover in respect of professional 
counselling that is recommended by a general practitioner for either of the marrying 
couple as a result of the unforeseen, unavoidable and permanent cancellation of the 
wedding due to the death of either of the marrying couple or either of the marrying 
couple electing not to continue with the marriage.

The claim limit is £1,000 irrespective of the level of cover selected.
`
    },
    {
      title: "Legal Expenses",
      description: `All ` + MyTheme.pageTitle + ` Wedding Insurance policies offer cover for any legal costs and 
expenses incurred by you, in the pursuit of legal proceedings by you or your 
personal representative(s) for compensation and/or damages arising from or out of 
your injury or death. 

The claim limit varies between £5,000 and £20,000 depending on the level of cover 
selected.
`
    },
    {
      title: "Public Liability",
      description: `**Section 11** - Marrying couple only - Are looking for Public Liability Insurance for 
your Wedding? Then you are in the right place as all the `+ MyTheme.pageTitle + ` Wedding 
Insurance policies include £2 million of Public Liability cover for the Marrying Couple 
as standard. What's more, we offer 2 optional Public Liability upgrades too.

**Section 12** - Upgrade to include guests and £2 million cover limit - If you 
need your Wedding Guests to have Public Liability cover too then we offer a policy 
upgrade where you can extend this element of cover to include them too. To do 
this, you will need to pay the appropriate additional premium and then your Guests 
will also benefit from Public Liability cover up to £2 million.

**Section 13** - Upgrade to include guests and £5 million cover limit - If £2 
million Public Liability cover is not enough, maybe because you're getting married in 
a stately home or National Trust property (who often require a higher level of 
Liability cover), then the `+ MyTheme.pageTitle + ` Wedding Insurance policies also offer a £5 
million Public Liability upgrade for additional peace of mind on your big day. This 
increased level of cover is only available if you upgrade to include your wedding 
guests`
    },
    {
      title: "Marquee & Event Equipment",
      description: `If you are holding your Wedding ceremony or reception in a standalone marquee, then 
it makes sense to cover this within your Wedding Insurance policy, as you would be 
liable to the marquee hire company for any loss or damage sustained during the period 
of hire.

The `+ MyTheme.pageTitle + ` Wedding Insurance offers an Optional upgrade to include
Marquee & Mobile W.C. unit and Event Equipment extension that will cover you for 
the loss or damage of your hired marquee during the period of hire.

The additional premium payable will depend on the cost of the marquee itself. For 
instance, if the marquee is worth £20,000 your additional premium would be less than 
if the marquee was worth £50,000.`
    },
    {
      title: "Ceremonial Swords",
      description: `Ceremonial Swords can play a big part in couples' weddings or civil partnerships, 
typically those with in the armed forces but also in some religious ceremonies. 

Military Weddings have numerous traditions, including involving Ceremonial Swords 
either in a guard of honour for the Marrying Couple or even in the cutting of the cake 
and these swords can be incredibly expensive and so it is wise to insure them as well 
as the rest of your big day.

Should you choose to upgrade and pay the additional premium, you will benefit 
from up to £20,000 worth of cover should you lose or damage any borrowed or hired 
Ceremonial Swords (and accompanying regalia) within the 7 days leading up to the 
wedding or for the 48 hours after the wedding.`
    }, {
      title: "Coronavirus Cover",
      description: `After everything that has happened across the world over the last 2 years, you 
might decide to take the upgrade to add coronavirus cover.

If you do you upgrade and pay the additional premium you will be covered up to the 
claim limit selected, for cancellation due to the death or sickness of the Marrying 
couple or a Relative, due to contracting Coronavirus, within 10 days of the Wedding 
date, which would make the continuance of the Wedding inappropriate. This is 
subject to the Marrying couple or Relative all being fully vaccinated (including 
booster vaccination where appropriate) in accordance with government guidelines.

You will also be covered for the additional re-arrangement costs, if applicable. `
    }

  ],
};

//events procedure

export const eventsProgressBar = [
  "Your details",
  "Event Details",
  "Available Products",
  "The Legal Bit",
  "Complete",
];

// export const weddingFaq = [
//   {
//     question: `Is wedding insurance paid monthly?`,
//     answer: `No, wedding insurance is just a one-off payment, showing what excellent value for money it is.`,
//   },
//   {
//     question: `When should we take out wedding insurance?`,
//     answer: `Ideally, you should take it out as soon as you have paid any deposits or signed a contract that means you will lose money if you are forced to change your plans. You can arrange our insurance at any time up to 24 months before the big day.`,
//   },
//   {
//     question: `Who can buy wedding insurance?`,
//     answer: `Anyone can buy a wedding insurance policy, for instance parents of the bride or groom, but the policy must be in the name of the two people who are getting married as it is their wedding being covered.`,
//   },
//   {
//     question: `I have already paid a deposit. Will this be covered?`,
//     answer: `Yes - provided you are not aware of any particular circumstances that may give rise to a claim when you take out the policy.`,
//   },
//   {
//     question: `Does wedding insurance cover relative's money?`,
//     answer: `Our wedding insurance will cover money paid by relatives of the couple, providing they have a demonstrable financial interest, such as a receipt or booking confirmation.

//       All claims will have to be submitted by the insured couple and then the settlement passed on to the person who paid.
//       `,
//   },
//   {
//     question: `We are getting married in a small civil ceremony with a larger church blessing & reception 2 weeks later. Are both dates covered?`,
//     answer: `Both dates will be covered provided they are not more than 21 days apart. If your civil marriage and blessing/reception are more than 21 days apart then you will need to take out two separate policies, one for each date. The two events must be less than 21 days apart to be covered under the same policy.`,
//   },
//   {
//     question: `My fiancé lives abroad. Can we take out a policy?`,
//     answer: `Yes - provided one of you is normally resident here in the UK you can take out a policy.`,
//   },
//   {
//     question: `My fiancé is in the Armed Forces. Are we covered if he is unexpectedly posted abroad?`,
//     answer: `Yes - provided that there was no notice of possible cancellation of leave at the time the policy was taken out.`,
//   },
//   {
//     question: `What about if one of us decides not to go ahead with the marriage after all?`,
//     answer: `This is known as "disinclination to proceed" and is not an insured reason for cancellation, sadly. However, you may be able to claim for cost of professional counselling under Section 9 of the policy.`,
//   },
//   {
//     question: `Is family bereavement covered by wedding insurance?`,
//     answer: `Cancellation or rearrangement due to family bereavement is covered under section 1 of our wedding insurance policy if it makes going ahead with the wedding inappropriate. However, you must answer the medical declaration for the policy in respect of all people on whom your wedding plans depend before you purchase the policy. If you answer yes to any of the medical questions with regard to a relative, you will not be covered in relation to that medical condition. You will of course be covered for anything unrelated to that relative's condition.If you are in doubt please contact us on 0161 505 1420, before you purchase the policy.`,
//   },
//   {
//     question: `What happens if the professional wedding photographs fail because the film or digital storage media is faulty and there are no copies available?`,
//     answer: `Then the reasonable costs of taking replacement formal photographs of the couple and the immediate Wedding party can be claimed.`,
//   },
//   {
//     question: `I have an existing medical condition. Can this be covered?`,
//     answer: `Most existing medical conditions are insured automatically, both for the Couple and also their close relatives. There is no cover, however, for Pre-existing medical conditions that have resulted in a terminal prognosis or where the condition is awaiting treatment or investigation at a hospital or you are awaiting the results of tests or investigations, unless you have declared details to us and we have agreed to insure them. You can call us to discuss a medical condition on 0161 505 1420.`,
//   },
//   {
//     question: `What happens if one of the suppliers goes bust?`,
//     answer: `Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.`,
//   },
//   {
//     question: `Our wedding venue is flooded just before the big day. Are we covered?`,
//     answer: `Then you may claim for any irrecoverable deposits that you may have paid and also for the necessary additional costs in arranging an equivalent alternative. A definition of wedding service suppliers is included in the policy wording.`,
//   },
//   {
//     question: `We are hiring a marquee and will need to insure it. Is this included or can it be added to the policy`,
//     answer: `There is an optional extension that you can take to add this cover for up to £20,000 or £50,000, subject to payment of the appropriate additional premium.`,
//   },
//   {
//     question: `Our venue requires us to provide insurance in case any of us or the guests causes accidental damage during the reception. Is this included?`,
//     answer: `The marrying couple’s Public liability is included as standard up to £2m. There is an optional extension available to add cover for the liabilities of your wedding party up to £2m in total. This can be further increased to be £5m with the additional extension.`,
//   },
//   {
//     question: `Is my honeymoon or overseas wedding trip insured on this policy?`,
//     answer: `No - you will need to take out separate travel insurance for your honeymoon or overseas wedding trip, which would normally include cancellation cover if you are not able to travel as planned.`,
//   },
//   {
//     question: `Does the wedding insurance policy have an excess?`,
//     answer: `Yes, there is an excess of £100 applicable under all sections of the policy, except for the Public Liability sections (sections 12, 13 & 14) which have an excess of £250.`,
//   },
// ];



//vehicle checkout V2 info


export const insuranceOptions = [
  {
    title: 'Alloy Insurance',
    origin: 'Alloy Wheel',
    shortOrigin: 'alloy',
    image: '../assets/alloy2x.webp'
  },
  {
    title: 'Tyre Insurance',
    origin: 'Tyre',
    shortOrigin: 'tyre',
    image: 'Tyreimg'

  },
  {
    title: 'Cosmetic Insurance',
    origin: 'Cosmetic Repair',
    shortOrigin: 'cosmetic',
    image: 'CosmeticImg'

  },
  {
    title: 'Gap Insurance',
    origin: 'Gap',
    shortOrigin: 'gap',
    image: 'GapImg',


  },
  {
    title: 'Vehicle Warranty',
    origin: 'MBI',
    shortOrigin: 'mbi',
    image: 'MechanicalImg'

  },
  {
    title: 'Excess Insurance',
    origin: 'Excess',
    shortOrigin: 'excess',
    image: 'CosmeticImg'

  }
]

